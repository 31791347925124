import { io } from 'socket.io-client';
const socket = io("http://localhost:3001/admin",{
 path: "/socket.io"
  });

socket.on("connect", () => {
  console.log("Socket connected to server");
});

export default socket;
