import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const OrderTypeRatio = ({ data }) => {
    const chartData = {
        labels: ['Bell Orders', 'Rogers Orders', 'Rogers Mobility Orders'],
        datasets: [
            {
                label: 'Orders Type',
                data: [
                    data.ordersDetails?.bellOrders?.length ?? 0,
                    data.ordersDetails?.rogersOrders?.length ?? 0,
                    data.ordersDetails?.rogersMobilityOrders?.length ?? 0,
                ],
                backgroundColor: ['#34A853', '#EA4335', '#4285F4'], 
                hoverBackgroundColor: ['#2C8C46', '#D92B26', '#3367D6'], 
                borderColor: ['#1E1E2F'],
                borderWidth: 2,
                cutout: '80%', // Optional: Adjust the inner cutout size
            },
        ],
    };

    const chartOptions = {
        plugins: {
            legend: {
                display: false,
            },
        },
        maintainAspectRatio: false,
        responsive: true,
    };

    return (
        <div
            style={{
                width: '300px', 
                height: '500px', 
                backgroundColor: '#1E1E2F',
                padding: '15px',
                borderRadius: '10px',
                boxShadow: '0 4px 10px rgba(0,0,0,0.5)',
            }}
        >
            <h3
                style={{
                    color: '#fff',
                    textAlign: 'center',
                    fontSize: '14px', 
                    fontWeight: 'bold',
                    marginBottom: '15px',
                }}
            >
                Orders Type
            </h3>
            <div
                style={{
                    height: '150px', 
                    width: '150px', 
                    margin: '0 auto',
                }}
            >
                <Doughnut data={chartData} options={chartOptions} />
            </div>

            <div
                style={{
                    marginTop: '15px',
                    color: '#fff',
                    fontSize: '12px', 
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '8px',
                    }}
                >
                    <p style={{ margin: 0, fontWeight: 'normal' }}>Bell</p>
                    <p>{data.ordersDetails?.bellOrders?.length ?? 0}</p>
                </div>

                {/* Rogers */}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '8px',
                    }}
                >
                    <p style={{ margin: 0, fontWeight: 'normal' }}>Rogers</p>
                    <p>{data.ordersDetails?.rogersOrders?.length ?? 0}</p>
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <p style={{ margin: 0, fontWeight: 'normal' }}>Mobility</p>
                    <p>{data.ordersDetails?.rogersMobilityOrders?.length ?? 0}</p>
                </div>
            </div>
        </div>
    );
};

export default OrderTypeRatio;
