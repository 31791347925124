import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";

const PriceForm = () => {
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    centerName: "",
    clientName: "",
    clientHomeBellPrice: "",
    clientHomeRogersPrice: "",
    clientTvBellPrice: "",
    clientTvRogersPrice: "",
    clientInternetBellPrice: "",
    clientInternetRogersPrice: "",
    distriButalHomeClient: "",
    distriButalInternetClient: "",
    distributalTVClient: "",
    telusHomeClient: "",
    telusInternetClient: "",
    telusTvClient: "",
    cogectoHomeClinet: "",
    cogecoInternetClient: "",
    cogectoTvClient: "",
    shawHomeClient: "",
    shawInterentClient: "",
    shawTvclient: "",
    bellMobilityClient: "",
    rogersMobilityClient_R: "",
    rogerMobilityClient_D: "",
    telusMobilityClient: "",
    kodoMobilityClient: "",
    chatrMobilityClient: "",
    fredomMobilityClient: "",
    virginMobilityClient: "",
    // center
    centerhomeBellPrice: "",
    centerHomeRogersPrice: "",
    centerTvBellPrice: "",
    centerTvRogersPrice: "",
    centerInternetBellPrice: "",
    centerInternetRogersPrice: "",
    distriButalHomeCenter: "",
    distriButalInternetCenter: "",
    distributalTVCenter: "",
    telusHomeCenter: "",
    telusInternetCenter: "",
    telusTvCenter: "",
    cogectoHomeCenter: "",
    cogecoInternetCenter: "",
    cogectoTvCenter: "",
    shawHomeCenter: "",
    shawInterentCenter: "",
    shawTvCenter: "",
    bellMobilityCenter: "",
    rogerMobilityCenter_R: "",
    rogerMobilityCenter_D: "",
    telusMobilityCenter: "",
    kodoMobilityCenter: "",
    chatrMobilityCenter: "",
    fredomMobilityCenter: "",
    virginMobilityCenter: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/register/users");
        setUsers(response.data);
      } catch (error) {
        toast.error("Failed to fetch users");
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validatePrices(formData);
    if (errors.length > 0) {
      errors.forEach((error) => toast.error(error));
      return;
    }
    try {
      await axios.post("/api/prices", formData);
      toast.success("Price Added..");
      setFormData({
        centerName: "",
        clientName: "",
        clientHomeBellPrice: "",
        clientHomeRogersPrice: "",
        clientTvBellPrice: "",
        clientTvRogersPrice: "",
        clientInternetBellPrice: "",
        clientInternetRogersPrice: "",
        distriButalHomeClient: "",
        distriButalInternetClient: "",
        distributalTVClient: "",
        telusHomeClient: "",
        telusInternetClient: "",
        telusTvClient: "",
        cogectoHomeClinet: "",
        cogecoInternetClient: "",
        cogectoTvClient: "",
        shawHomeClient: "",
        shawInterentClient: "",
        shawTvclient: "",
        bellMobilityClient: "",
        rogersMobilityClient_R: "",
        rogerMobilityClient_D: "",
        telusMobilityClient: "",
        kodoMobilityClient: "",
        chatrMobilityClient: "",
        fredomMobilityClient: "",
        virginMobilityClient: "",
        // center
        centerhomeBellPrice: "",
        centerHomeRogersPrice: "",
        centerTvBellPrice: "",
        centerTvRogersPrice: "",
        centerInternetBellPrice: "",
        centerInternetRogersPrice: "",
        distriButalHomeCenter: "",
        distriButalInternetCenter: "",
        distributalTVCenter: "",
        telusHomeCenter: "",
        telusInternetCenter: "",
        telusTvCenter: "",
        cogectoHomeCenter: "",
        cogecoInternetCenter: "",
        cogectoTvCenter: "",
        shawHomeCenter: "",
        shawInterentCenter: "",
        shawTvCenter: "",
        bellMobilityCenter: "",
        rogerMobilityCenter_R: "",
        rogerMobilityCenter_D: "",
        telusMobilityCenter: "",
        kodoMobilityCenter: "",
        chatrMobilityCenter: "",
        fredomMobilityCenter: "",
        virginMobilityCenter: "",
      });
    } catch (error) {
      toast.error("Failed to submit form");
      console.error(error);
    }
  };
  const centers = users.filter((user) => user.role === "user");
  const clients = users.filter((user) => user.role === "client");
  const validatePrices = (formData) => {
    const errors = [];

    if (
      Number(formData.clientHomeBellPrice) <=
      Number(formData.centerHomeBellPrice)
    ) {
      errors.push(
        "Client Home Bell Price must be greater than Center Home Bell Price."
      );
    }
    if (
      Number(formData.clientHomeRogersPrice) <=
      Number(formData.centerHomeRogersPrice)
    ) {
      errors.push(
        "Client Home Rogers Price must be greater than Center Home Rogers Price."
      );
    }
    if (
      Number(formData.clientTvBellPrice) <= Number(formData.centerTvBellPrice)
    ) {
      errors.push(
        "Client TV Bell Price must be greater than Center TV Bell Price."
      );
    }
    if (
      Number(formData.clientTvRogersPrice) <=
      Number(formData.centerTvRogersPrice)
    ) {
      errors.push(
        "Client TV Rogers Price must be greater than Center TV Rogers Price."
      );
    }
    if (
      Number(formData.clientInternetBellPrice) <=
      Number(formData.centerInternetBellPrice)
    ) {
      errors.push(
        "Client Internet Bell Price must be greater than Center Internet Bell Price."
      );
    }
    if (
      Number(formData.clientInternetRogersPrice) <=
      Number(formData.centerInternetRogersPrice)
    ) {
      errors.push(
        "Client Internet Rogers Price must be greater than Center Internet Rogers Price."
      );
    }

    return errors;
  };

  return (
    <div className="container">
      <h2 className="text-center text-white">Set Prices</h2>
      <form
        className="p-4 border rounded bg-light shadow-sm"
        onSubmit={handleSubmit}
      >
        <div className="row mb-4">
          <div className="col-md-6">
            <h5>Incoming Prices From Client</h5>

            <select
              className="form-select"
              id="clientName"
              name="clientName"
              value={formData.clientName}
              onChange={handleChange}
              required
            >
              <option value="">Select Client Name</option>
              {clients.map((user) => (
                <option key={user._id.$oid} value={user.username}>
                  {user.username}
                </option>
              ))}
            </select>
            <div className="row mb-4">
              <div className="col-md-6">
                <label htmlFor="clientHomeBellPrice" className="form-label">
                  Home Bell
                </label>
                <input
                  type="number"
                  className="form-control"
                  id=""
                  name="clientHomeBellPrice"
                  value={formData.clientHomeBellPrice}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="clientHomeRogersPrice" className="form-label">
                  Home Rogers
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="clientHomeRogersPrice"
                  name="clientHomeRogersPrice"
                  value={formData.clientHomeRogersPrice}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="clientTvBellPrice" className="form-label">
                  TV Bell Price
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="clientTvBellPrice"
                  name="clientTvBellPrice"
                  value={formData.clientTvBellPrice}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="clientTvRogersPrice" className="form-label">
                  TV Rogers
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="clientTvRogersPrice"
                  name="clientTvRogersPrice"
                  value={formData.clientTvRogersPrice}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="clientInternetBellPrice" className="form-label">
                  Internet Bell
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="clientInternetBellPrice"
                  name="clientInternetBellPrice"
                  value={formData.clientInternetBellPrice}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <label
                  htmlFor="clientInternetRogersPrice"
                  className="form-label"
                >
                  Internet Rogers
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="clientInternetRogersPrice"
                  name="clientInternetRogersPrice"
                  value={formData.clientInternetRogersPrice}
                  onChange={handleChange}
                  required
                />
              </div>
              {/* new div */}
              <div className="col-md-6">
                <label htmlFor="distriButalHomeClient" className="form-label">
                  Distributal Home
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="distriButalHomeClient"
                  name="distriButalHomeClient"
                  value={formData.distriButalHomeClient}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <label
                  htmlFor="distriButalInternetClient"
                  className="form-label"
                >
                  Distributal Interent
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="distriButalInternetClient"
                  name="distriButalInternetClient"
                  value={formData.distriButalInternetClient}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="distributalTVClient" className="form-label">
                  Distributal TV
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="distributalTVClient"
                  name="distributalTVClient"
                  value={formData.distributalTVClient}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="telusHomeClient" className="form-label">
                  Telus Home
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="telusHomeClient"
                  name="telusHomeClient"
                  value={formData.telusHomeClient}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="telusInternetClient" className="form-label">
                  Telus Interent
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="telusInternetClient"
                  name="telusInternetClient"
                  value={formData.telusInternetClient}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="telusTvClient" className="form-label">
                  Telus TV
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="telusTvClient"
                  name="telusTvClient"
                  value={formData.telusTvClient}
                  onChange={handleChange}
                  required
                />
              </div>
              {/* Cogeco */}
              <div className="col-md-6">
                <label htmlFor="cogectoHomeClinet" className="form-label">
                  Cogeco Home
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="cogectoHomeClinet"
                  name="cogectoHomeClinet"
                  value={formData.cogectoHomeClinet}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="cogecoInternetClient" className="form-label">
                  Cogeco Interent
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="cogecoInternetClient"
                  name="cogecoInternetClient"
                  value={formData.cogecoInternetClient}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="cogectoTvClient" className="form-label">
                  Cogeco TV
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="cogectoTvClient"
                  name="cogectoTvClient"
                  value={formData.cogectoTvClient}
                  onChange={handleChange}
                  required
                />
              </div>
              {/* shaw */}
              <div className="col-md-6">
                <label htmlFor="shawHomeClient" className="form-label">
                  Shaw Home
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="shawHomeClient"
                  name="shawHomeClient"
                  value={formData.shawHomeClient}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="shawInterentClient" className="form-label">
                  Shaw Interent
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="shawInterentClient"
                  name="shawInterentClient"
                  value={formData.shawInterentClient}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="shawTvclient" className="form-label">
                  Shaw TV
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="shawTvclient"
                  name="shawTvclient"
                  value={formData.shawTvclient}
                  onChange={handleChange}
                  required
                />
              </div>
              <hr className="mt-2 text-primary border-2" />
              <h5>Mobility Prices</h5>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="bellMobilityClient">Bell Mobility</label>
                <input
                  type="number"
                  name="bellMobilityClient"
                  id="bellMobilityClient"
                  className="form-control"
                  value={formData.bellMobilityClient}
                  onChange={handleChange}
                  required
                  min={0}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="rogersMobilityClient_R">
                  Rogers Mobility-R
                </label>
                <input
                  type="number"
                  name="rogersMobilityClient_R"
                  id="rogersMobilityClient_R"
                  className="form-control"
                  value={formData.rogersMobilityClient_R}
                  onChange={handleChange}
                  required
                  min={0}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="rogerMobilityClient_D">Rogers Mobility-D</label>
                <input
                  type="number"
                  name="rogerMobilityClient_D"
                  id="rogerMobilityClient_D"
                  className="form-control"
                  value={formData.rogerMobilityClient_D}
                  onChange={handleChange}
                  required
                  min={0}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <label htmlFor="telusMobilityClient">Telus Mobility</label>
                <input
                  type="number"
                  name="telusMobilityClient"
                  id="telusMobilityClient"
                  className="form-control"
                  value={formData.telusMobilityClient}
                  onChange={handleChange}
                  required
                  min={0}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="chatrMobilityClient">Chatr Mobility</label>
                <input
                  type="number"
                  name="chatrMobilityClient"
                  id="chatrMobilityClient"
                  className="form-control"
                  value={formData.chatrMobilityClient}
                  onChange={handleChange}
                  required
                  min={0}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="fredomMobilityClient">Freedom Mobility</label>
                <input
                  type="number"
                  name="fredomMobilityClient"
                  id="fredomMobilityClient"
                  className="form-control"
                  value={formData.fredomMobilityClient}
                  onChange={handleChange}
                  required
                  min={0}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="kodoMobilityClient">koodo Mobility</label>
                <input
                  type="number"
                  name="kodoMobilityClient"
                  id="kodoMobilityClient"
                  className="form-control"
                  value={formData.kodoMobilityClient}
                  onChange={handleChange}
                  required
                  min={0}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="virginMobilityClient">Virgin Mobility</label>
                <input
                  type="number"
                  name="virginMobilityClient"
                  id="virginMobilityClient"
                  className="form-control"
                  value={formData.virginMobilityClient}
                  onChange={handleChange}
                  required
                  min={0}
                />
              </div>
            </div>
          </div>

          <div className="col-md-6  border-start border-black">
            <h5>Outgoing Prices for Center</h5>
            <select
              className="form-select"
              id="centerName"
              name="centerName"
              value={formData.centerName}
              onChange={handleChange}
              required
            >
              <option value="">Select Center Name</option>
              {centers.map((user) => (
                <option key={user._id.$oid} value={user.username}>
                  {user.username}
                </option>
              ))}
            </select>
            <div className="row mb-4">
              <div className="col-md-6">
                <label htmlFor="centerhomeBellPrice" className="form-label">
                  Home Bell Price
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="centerhomeBellPrice"
                  name="centerhomeBellPrice"
                  value={formData.centerhomeBellPrice}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="centerHomeRogersPrice" className="form-label">
                  Home Rogers Price
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="centerHomeRogersPrice"
                  name="centerHomeRogersPrice"
                  value={formData.centerHomeRogersPrice}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="centerTvBellPrice" className="form-label">
                  TV Bell Price
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="centerTvBellPrice"
                  name="centerTvBellPrice"
                  value={formData.centerTvBellPrice}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="centerTvRogersPrice" className="form-label">
                  TV Rogers Price
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="centerTvRogersPrice"
                  name="centerTvRogersPrice"
                  value={formData.centerTvRogersPrice}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="centerInternetBellPrice" className="form-label">
                  Internet Bell Price
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="centerInternetBellPrice"
                  name="centerInternetBellPrice"
                  value={formData.centerInternetBellPrice}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <label
                  htmlFor="centerInternetRogersPrice"
                  className="form-label"
                >
                  Internet Rogers
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="centerInternetRogersPrice"
                  name="centerInternetRogersPrice"
                  value={formData.centerInternetRogersPrice}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="distriButalHomeCenter" className="form-label">
                  Distributal Home
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="distriButalHomeCenter"
                  name="distriButalHomeCenter"
                  value={formData.distriButalHomeCenter}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <label
                  htmlFor="distriButalInternetCenter"
                  className="form-label"
                >
                  Distributal Interent
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="distriButalInternetCenter"
                  name="distriButalInternetCenter"
                  value={formData.distriButalInternetCenter}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="distributalTVCenter" className="form-label">
                  Distributal TV
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="distributalTVCenter"
                  name="distributalTVCenter"
                  value={formData.distributalTVCenter}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="telusHomeCenter" className="form-label">
                  Telus Home
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="telusHomeCenter"
                  name="telusHomeCenter"
                  value={formData.telusHomeCenter}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="telusInternetCenter" className="form-label">
                  Telus Interent
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="telusInternetCenter"
                  name="telusInternetCenter"
                  value={formData.telusInternetCenter}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="telusTvCenter" className="form-label">
                  Telus TV
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="telusTvCenter"
                  name="telusTvCenter"
                  value={formData.telusTvCenter}
                  onChange={handleChange}
                  required
                />
              </div>
              {/* Cogeco */}
              <div className="col-md-6">
                <label htmlFor="cogectoHomeCenter" className="form-label">
                  Cogeco Home
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="cogectoHomeCenter"
                  name="cogectoHomeCenter"
                  value={formData.cogectoHomeCenter}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="cogecoInternetCenter" className="form-label">
                  Cogeco Interent
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="cogecoInternetCenter"
                  name="cogecoInternetCenter"
                  value={formData.cogecoInternetCenter}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="cogectoTvCenter" className="form-label">
                  Cogeco TV
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="cogectoTvCenter"
                  name="cogectoTvCenter"
                  value={formData.cogectoTvCenter}
                  onChange={handleChange}
                  required
                />
              </div>
              {/* shaw */}
              <div className="col-md-6">
                <label htmlFor="shawHomeCenter" className="form-label">
                  Shaw Home
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="shawHomeCenter"
                  name="shawHomeCenter"
                  value={formData.shawHomeCenter}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="shawInterentCenter" className="form-label">
                  Shaw Interent
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="shawInterentCenter"
                  name="shawInterentCenter"
                  value={formData.shawInterentCenter}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="shawTvCenter" className="form-label">
                  Shaw TV
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="shawTvCenter"
                  name="shawTvCenter"
                  value={formData.shawTvCenter}
                  onChange={handleChange}
                  required
                />
              </div>
              <hr className="mt-2 text-primary border-2" />
              <h5>Mobility Prices</h5>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="bellMobilityCenter">Bell Mobility</label>
                <input
                  type="number"
                  name="bellMobilityCenter"
                  id="bellMobilityCenter"
                  className="form-control"
                  value={formData.bellMobilityCenter}
                  onChange={handleChange}
                  required
                  min={0}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="rogerMobilityCenter_R">Rogers Mobility-R</label>
                <input
                  type="number"
                  name="rogerMobilityCenter_R"
                  id="rogerMobilityCenter_R"
                  className="form-control"
                  value={formData.rogerMobilityCenter_R}
                  onChange={handleChange}
                  required
                  min={0}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="rogerMobilityCenter_D">Rogers Mobility-D</label>
                <input
                  type="number"
                  name="rogerMobilityCenter_D"
                  id="rogerMobilityCenter_D"
                  className="form-control"
                  value={formData.rogerMobilityCenter_D}
                  onChange={handleChange}
                  required
                  min={0}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="telusMobilityCenter">Telus Mobility</label>
                <input
                  type="number"
                  name="telusMobilityCenter"
                  id="telusMobilityCenter"
                  className="form-control"
                  value={formData.telusMobilityCenter}
                  onChange={handleChange}
                  required
                  min={0}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="chatrMobilityCenter">Chatr Mobility</label>
                <input
                  type="number"
                  name="chatrMobilityCenter"
                  id="chatrMobilityCenter"
                  className="form-control"
                  value={formData.chatrMobilityCenter}
                  onChange={handleChange}
                  required
                  min={0}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="fredomMobilityCenter">Freedom Mobility</label>
                <input
                  type="number"
                  name="fredomMobilityCenter"
                  id="fredomMobilityCenter"
                  className="form-control"
                  value={formData.fredomMobilityCenter}
                  onChange={handleChange}
                  required
                  min={0}
                />
              </div>

            </div>
            <div className="row">
            <div className="col-md-6">
                <label htmlFor="kodoMobilityCenter">koodo Mobility</label>
                <input
                  type="number"
                  name="kodoMobilityCenter"
                  id="kodoMobilityCenter"
                  className="form-control"
                  value={formData.kodoMobilityCenter}
                  onChange={handleChange}
                  required
                  min={0}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="virginMobilityCenter">Virgin Mobility</label>
                <input
                  type="number"
                  name="virginMobilityCenter"
                  id="virginMobilityCenter"
                  className="form-control"
                  value={formData.virginMobilityCenter}
                  onChange={handleChange}
                  required
                  min={0}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="text-center">
          <button type="submit" className="btn btn-primary px-5">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default PriceForm;
