import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from '../../layout/Footer'
const RogerMobility = () => {
  const [bankStatements, setBankStatements] = useState({});
  const [formData, setFormData] = useState({
    formType: "",
    orderType: "",
    personalDetails: {
      fullName: "",
      dateOfBirth: "",
      contactNumber: "",
      email: "",
      address: {
        street: "",
        aptSuite: "",
        city: "",
        province: "",
        postalCode: "",
      },
    },
    businessDetails: {
      name: "",
      address: {
        street: "",
        aptSuite: "",
        city: "",
        province: "",
        postalCode: "",
      },
      businessPhone: "",
      businessEmail: "",
      authorizedUserName: "",
      authorizedUserPhone: "",
      authorizedUserEmail: "",
      hstNumber: "",
      authorizedUserPhotoId: "",
    },
    isSameAsBusiness: "Yes",
    shippingAddress: {
      street: "",
      aptSuite: "",
      city: "",
      province: "",
      postalCode: "",
    },
    rogerInternectAcc: "",
    // col-3
    // orderType yes
    creditInfoOption: "",
    licenseAndSin: {
      driverLicense: {
        licensephotoId: "",
        driversLicenseNumber: "",
        driversLicenseExpiryDate: "",
        driverLicProvince: "",
      },
      sin: "",
    },
    photoIdSinBankInfo: {
      photoId: [
        {
          photoId: "",
        },
      ],
      sin: [
        {
          sin: "",
        },
      ],
      bankinfo: [
        {
          bankInstitutionNumber: "",
          bankTransitNumber: "",
          bankAccountNumber: "",
          voidCheque: "",
        },
      ],
    },
    licenseAndBankInfo: {
      licenseAndBankInfo: [
        {
          licensephotoId: "",
          driversLicenseNumber: "",
          driversLicenseExpiryDate: "",
          driverLicProvince: "",
        },
      ],
      bankinfo: [
        {
          bankInstitutionNumber: "",
          bankTransitNumber: "",
          bankAccountNumber: "",
          voidCheque: "",
        },
      ],
    },
    bankStatements: ["", "", ""],
    isBankSameAddress: "",
    businessBankAddress: {
      bankAddress: [
        {
          street: "",
          aptSuite: "",
          city: "",
          province: "",
          postalCode: "",
        },
      ],
    },

    dealerNotes: "",
    tradeReferences: [
      { companyName: "", contactName: "", contactNumber: "" },
      { companyName: "", contactName: "", contactNumber: "" },
    ],
    articleofIncorporation: "",
  });

  console.log("Submitte form data", formData);
  const [portSections, setPortSections] = useState([
    {
      newLine: false,
      porteduserName: "",
      portedPhoneNumber: "",
      portedProviderName: "",
      portedAccountNumber: "",
      portedPlanInfo: "",
      includeHardware: "",
      makeModelStorageColor: "",
      upfrontEdgeDiscount: false,
      simCardNumber: "",
    },
  ]);
  console.log("port section", portSections);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split(".");
    let updatedFormData = { ...formData };
    let currentLevel = updatedFormData;
    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        currentLevel[key] = value;
      } else {
        currentLevel[key] = currentLevel[key] || {};
        currentLevel = currentLevel[key];
      }
    });
    setFormData(updatedFormData);
  };

  const handleFileChange = async (e) => {
    const { name, files } = e.target;
    const file = files[0];

    if (file) {
      const fileSizeLimit = 5 * 1024 * 1024;
      const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];

      if (file.size > fileSizeLimit) {
        toast.error("File size exceeds the 5MB limit.");
        return;
      }
      if (!allowedTypes.includes(file.type)) {
        toast.error(
          "Invalid file format. Please upload JPEG, PNG, or PDF files."
        );
        return;
      }

      // Convert file to base64
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        setFormData((prev) => ({
          ...prev,
          licenseAndSin: {
            ...prev.licenseAndSin,
            driverLicense: {
              ...prev.licenseAndSin.driverLicense,
              [name.split(".").pop()]: base64String,
            },
          },
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePortSectionChange = (index, e) => {
    const { name, value, type, checked } = e.target;
    const updatedSections = [...portSections];
    updatedSections[index][name] = type === "checkbox" ? checked : value;
    setPortSections(updatedSections);
  };
  const handleBankStatementChange = (e, index) => {
    const { files } = e.target;

    if (files.length > 0) {
      setBankStatements((prevStatements) => ({
        ...prevStatements,
        [index]: files[0],
      }));
    }
  };
  const addNewPortSection = () => {
    setPortSections([
      ...portSections,
      {
        porteduserName: "",
        portedPhoneNumber: "",
        portedProviderName: "",
        portedAccountNumber: "",
        portedPlanInfo: "",
        includeHardware: "",
        makeModelStorageColor: "",
        upfrontEdgeDiscount: false,
        simCardNumber: "",
      },
    ]);
  };

  const deletePortSection = (index) => {
    if (portSections.length > 1) {
      const updatedSections = portSections.filter((_, i) => i !== index);
      setPortSections(updatedSections);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const centerName = localStorage.getItem("username") || "";
      const response = await axios.post("/api/RogerMobilityForm", {
        ...formData,
        centerName: centerName,
        portSections,
      });
      if (response.status === 201) {
        toast.success("Form submitted successfully!");
      }
    } catch (error) {
      toast.error("Error submitting form. Please try again.");
    }
  };

  return (
    <>
    <Container
      fluid
      style={{
        padding: "20px",
        backgroundColor: "#F0F4FF",
      }}
    >
      <ToastContainer />
      <Form onSubmit={handleSubmit} encType="multipart/form-data">
        <Row>
          <Col md={3} style={{ borderRight: "1px solid #ddd" }}>
            <Form.Group controlId="formType">
              <Form.Label>Form Type</Form.Label>
              <Form.Control
                as="select"
                id="formType"
                name="formType"
                value={formData.formType}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Select Type
                </option>
                <option value="Rogers Mobility_R">Rogers Mobility-R</option>
                <option value="Rogers Mobility_D">Rogers Mobility-D</option>
                <option value="Bell Mobility">Bell Mobility</option>
                <option value="Telus Mobility">Telus Mobility</option>
                <option value="Koodo Mobility">Koodo Mobility</option>
                <option value="Chatr Mobility">Chatr Mobility</option>
                <option value="Freedom Mobility">Freedom Mobility</option>
                <option value="Virgin Mobility">Virgin Mobility</option>
              </Form.Control>
              {formData.formType === "" && (
                <span className="text-danger">Please must select a type</span>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          {/* Col 1 - Personal Info */}
          <Col
            md={3}
            style={{
              borderRight: "1px solid #ddd",
              marginRight: "20px",
              backgroundColor: "#fff",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              padding: "15px",
              borderRadius: "5px",
            }}
          >
            <h5>Order Type</h5>
            <div
              style={{
                backgroundColor: "#fff",
                padding: "20px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "5px",
                marginBottom: "20px",
              }}
            >
              <Form.Group>
                <Form.Label>Is this a business order?</Form.Label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Form.Check
                    type="radio"
                    label="Yes"
                    name="orderType"
                    value="Yes"
                    checked={formData.orderType === "Yes"}
                    onChange={handleChange}
                    style={{ marginRight: "10px" }}
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    name="orderType"
                    value="No"
                    checked={formData.orderType === "No"}
                    onChange={handleChange}
                  />
                </div>
              </Form.Group>
              <Form.Text>Changing this will reset the form</Form.Text>
            </div>
            {formData.orderType === "No" ? (
              <Row style={{ padding: "10px" }}>
                <h5>Personal Info</h5>
                <Form.Group>
                  <Form.Label>Full Legal Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="personalDetails.fullName"
                    value={formData.personalDetails.fullName}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Date of Birth</Form.Label>
                  <Form.Control
                    type="date"
                    name="personalDetails.dateOfBirth"
                    value={formData.personalDetails.dateOfBirth}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Contact No.</Form.Label>
                  <Form.Control
                    type="text"
                    name="personalDetails.contactNumber"
                    value={formData.personalDetails.contactNumber}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Customer Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="personalDetails.email"
                    value={formData.personalDetails.email}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Street Address</Form.Label>
                  <Form.Control
                    type="text"
                    name="personalDetails.address.street"
                    value={formData.personalDetails.address.street}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Apt/Suite</Form.Label>
                  <Form.Control
                    type="text"
                    name="personalDetails.address.aptSuite"
                    value={formData.personalDetails.address.aptSuite}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    name="personalDetails.address.city"
                    value={formData.personalDetails.address.city}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Province</Form.Label>
                  <Form.Control
                    as="select"
                    name="personalDetails.address.province"
                    value={formData.personalDetails.address.province}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled>
                      Province
                    </option>
                    <option value="Alberta">Alberta</option>
                    <option value="BC">British Columbia</option>
                    <option value="MB">Manitoba</option>
                    <option value="NB">New Brunswick</option>
                    <option value="NL">Newfoundland and Labrador</option>
                    <option value="NS">Nova Scotia</option>
                    <option value="ON">Ontario</option>
                    <option value="PE">Prince Edward Island</option>
                    <option value="QC">Quebec</option>
                    <option value="SK">Saskatchewan</option>
                    <option value="NT">Northwest Territories</option>
                    <option value="NU">Nunavut</option>
                    <option value="YT">Yukon</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Postal Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="personalDetails.address.postalCode"
                    value={formData.personalDetails.address.postalCode}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Row>
            ) : (
              <Row>
                <h5>Business Info</h5>
                <Form.Group>
                  <Form.Label>Business Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="businessDetails.name"
                    value={formData.businessDetails.name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Business Address</Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder="Number & Street"
                      name="businessDetails.address.street"
                      value={formData.businessDetails.address.street}
                      onChange={handleChange}
                    />
                  </Col>
                  <Row>
                    <Col md={4}>
                      <Form.Label>Apt Suite</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Apt/Suite"
                        name="businessDetails.address.aptSuite"
                        value={formData.businessDetails.address.aptSuite}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col md={8}>
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="City"
                        name="businessDetails.address.city"
                        value={formData.businessDetails.address.city}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Col>
                  <Form.Label>Province</Form.Label>
                  <Form.Control
                    as="select"
                    name="businessDetails.address.province"
                    value={formData.businessDetails.address.province}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled>
                      Province
                    </option>
                    <option value="Alberta">Alberta</option>
                    <option value="BC">British Columbia</option>
                    <option value="MB">Manitoba</option>
                    <option value="NB">New Brunswick</option>
                    <option value="NL">Newfoundland and Labrador</option>
                    <option value="NS">Nova Scotia</option>
                    <option value="ON">Ontario</option>
                    <option value="PE">Prince Edward Island</option>
                    <option value="QC">Quebec</option>
                    <option value="SK">Saskatchewan</option>
                    <option value="NT">Northwest Territories</option>
                    <option value="NU">Nunavut</option>
                    <option value="YT">Yukon</option>
                  </Form.Control>
                </Col>
                <Col>
                  <Col lg={12}>
                    <Form.Label>Postal Code</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Postal Code"
                      name="businessDetails.address.postalCode"
                      value={formData.businessDetails.address.postalCode}
                      onChange={handleChange}
                    />
                  </Col>
                </Col>

                <Form.Group>
                  <Form.Label>Business Phone No.</Form.Label>
                  <Form.Control
                    type="text"
                    name="businessDetails.businessPhone"
                    value={formData.businessDetails.businessPhone}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Business Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="businessDetails.businessEmail"
                    value={formData.businessDetails.businessEmail}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Authorized User Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="businessDetails.authorizedUserName"
                    value={formData.businessDetails.authorizedUserName}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Authorized User Phone #</Form.Label>
                  <Form.Control
                    type="text"
                    name="businessDetails.authorizedUserPhone"
                    value={formData.businessDetails.authorizedUserPhone}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Authorized User Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    name="businessDetails.authorizedUserEmail"
                    value={formData.businessDetails.authorizedUserEmail}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>HST #</Form.Label>
                  <Form.Control
                    type="text"
                    name="businessDetails.hstNumber"
                    value={formData.businessDetails.hstNumber}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Authorized User Photo ID</Form.Label>
                  <Form.Control
                    type="file"
                    name="businessDetails.authorizedUserPhotoId"
                    onChange={handleFileChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    Is the shipping address the same as the business address?
                  </Form.Label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Form.Check
                      type="radio"
                      label="Yes"
                      name="isSameAsBusiness"
                      value="Yes"
                      checked={formData.isSameAsBusiness === "Yes"}
                      onChange={handleChange}
                      style={{ marginRight: "10px" }}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      name="isSameAsBusiness"
                      value="No"
                      checked={formData.isSameAsBusiness === "No"}
                      onChange={handleChange}
                    />
                  </div>
                </Form.Group>
              </Row>
            )}
            {formData.isSameAsBusiness === "No" && (
              <Row>
                <Form.Group controlId="street">
                  <Form.Label>Number & Street</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Number & Street"
                    name="shippingAddress.street"
                    value={formData.shippingAddress.street}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Col>
                  <Form.Group controlId="aptSuite">
                    <Form.Label>Apt/Suite</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Apt/Suite"
                      name="shippingAddress.aptSuite"
                      value={formData.shippingAddress.aptSuite}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formCity">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="City"
                      name="shippingAddress.city"
                      value={formData.shippingAddress.city}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Row>
                  <Col>
                    <Form.Label>Province</Form.Label>
                    <Form.Control
                      as="select"
                      name="shippingAddress.province"
                      value={formData.shippingAddress.province || ""}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled>
                        Province
                      </option>
                      <option value="Alberta">Alberta</option>
                      <option value="BC">British Columbia</option>
                      <option value="MB">Manitoba</option>
                      <option value="NB">New Brunswick</option>
                      <option value="NL">Newfoundland and Labrador</option>
                      <option value="NS">Nova Scotia</option>
                      <option value="ON">Ontario</option>
                      <option value="PE">Prince Edward Island</option>
                      <option value="QC">Quebec</option>
                      <option value="SK">Saskatchewan</option>
                      <option value="NT">Northwest Territories</option>
                      <option value="NU">Nunavut</option>
                      <option value="YT">Yukon</option>
                    </Form.Control>
                  </Col>
                  <Col>
                    <Form.Group controlId="postalCodeforShipping">
                      <Form.Label>Postal Code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Postal Code"
                        name="shippingAddress.postalCode"
                        value={formData.shippingAddress.postalCode}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Row>
            )}
            <Form.Group>
              <Form.Label>Rogers Internet Account #</Form.Label>
              <Form.Control
                type="text"
                name="rogerInternectAcc"
                value={formData.rogerInternectAcc}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          {/* Col 2 - Numbers to be Ported */}
          <Col
            md={4}
            style={{
              borderRight: "1px solid #ddd",
              marginRight: "5px",
              backgroundColor: "#fff",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              padding: "15px",
              borderRadius: "5px",
            }}
          >
            <h5>Numbers to be Ported</h5>
            {portSections.map((section, index) => (
              <div
                key={index}
                style={{
                  marginBottom: "20px",
                  border: "1px solid #ddd",
                  padding: "10px",
                  borderRadius: "5px",
                }}
              >
                <h6>Line {index + 1}</h6>
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    label="This is a new line"
                    name="newLine"
                    checked={section.newLine || false}
                    onChange={(e) => handlePortSectionChange(index, e)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>User Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="porteduserName"
                    value={section.porteduserName}
                    onChange={(e) => handlePortSectionChange(index, e)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="portedPhoneNumber"
                    value={section.portedPhoneNumber}
                    onChange={(e) => handlePortSectionChange(index, e)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Provider Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="portedProviderName"
                    value={section.portedProviderName}
                    onChange={(e) => handlePortSectionChange(index, e)}
                    disabled={section.newLine}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Account Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="portedAccountNumber"
                    value={section.portedAccountNumber}
                    onChange={(e) => handlePortSectionChange(index, e)}
                    disabled={section.newLine}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Plan Info</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="portedPlanInfo"
                    value={section.portedPlanInfo}
                    onChange={(e) => handlePortSectionChange(index, e)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    label="This line includes hardware"
                    name="includeHardware"
                    checked={section.includeHardware || false}
                    onChange={(e) => handlePortSectionChange(index, e)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Make/Model/Storage/Colour</Form.Label>
                  <Form.Control
                    type="text"
                    name="makeModelStorageColor"
                    value={section.makeModelStorageColor}
                    onChange={(e) => handlePortSectionChange(index, e)}
                    disabled={!section.includeHardware}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    label="Upfront Edge Discount"
                    name="upfrontEdgeDiscount"
                    checked={section.upfrontEdgeDiscount}
                    onChange={(e) => handlePortSectionChange(index, e)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>SIM Card Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="simCardNumber"
                    value={section.simCardNumber}
                    onChange={(e) => handlePortSectionChange(index, e)}
                  />
                </Form.Group>
                {portSections.length > 1 && (
                  <Button
                    variant="danger"
                    onClick={() => deletePortSection(index)}
                    style={{ marginTop: "10px" }}
                  >
                    Remove Line
                  </Button>
                )}
              </div>
            ))}
            <Button variant="success" onClick={addNewPortSection}>
              Add New Line
            </Button>
          </Col>

          {/* Col 3 */}
          <Col md={4}>
            {formData.orderType === "No" && (
              <div
                style={{
                  backgroundColor: "#fff",
                  padding: "20px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "5px",
                  marginBottom: "20px",
                }}
              >
                <h5>CREDIT INFO</h5>
                <Form.Group>
                  <Form.Label>Choose an option:</Form.Label>
                  <Form.Check
                    type="radio"
                    label="Provide Driver's License and SIN"
                    name="creditInfoOption"
                    value="licenseAndSin"
                    checked={formData.creditInfoOption === "licenseAndSin"}
                    onChange={handleChange}
                  />
                  <Form.Check
                    type="radio"
                    label="Provide Driver's License and Bank Info"
                    name="creditInfoOption"
                    value="licenseAndBankInfo"
                    checked={formData.creditInfoOption === "licenseAndBankInfo"}
                    onChange={handleChange}
                  />
                  <Form.Check
                    type="radio"
                    label="Provide Photo ID, SIN, and Bank Info"
                    name="creditInfoOption"
                    value="photoIdSinBankInfo"
                    checked={formData.creditInfoOption === "photoIdSinBankInfo"}
                    onChange={handleChange}
                  />
                </Form.Group>

                {(formData.creditInfoOption === "licenseAndSin" ||
                  formData.creditInfoOption === "licenseAndBankInfo") && (
                  <>
                    <Form.Group>
                      <Form.Label>Driver's License Photo</Form.Label>
                      <Form.Control
                        type="file"
                        name="licenseAndSin.driverLicense.licensephotoId"
                        onChange={handleFileChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Driver's License Number</Form.Label>
                      <Form.Control
                        type="text"
                        name="licenseAndSin.driverLicense.driversLicenseNumber"
                        value={
                          formData.licenseAndSin.driverLicense
                            .driversLicenseNumber
                        }
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Driver's License Expiry Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="licenseAndSin.driverLicense.driversLicenseExpiryDate"
                        value={
                          formData.licenseAndSin.driverLicense
                            .driversLicenseExpiryDate
                        }
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Province</Form.Label>
                      <Form.Control
                        as="select"
                        name="licenseAndSin.driverLicense.driverLicProvince"
                        value={
                          formData.licenseAndSin.driverLicense.driverLicProvince
                        }
                        onChange={handleChange}
                        required
                      >
                        <option value="" disabled>
                          Province
                        </option>
                        <option value="Alberta">Alberta</option>
                        <option value="BC">British Columbia</option>
                        <option value="MB">Manitoba</option>
                        <option value="NB">New Brunswick</option>
                        <option value="NL">Newfoundland and Labrador</option>
                        <option value="NS">Nova Scotia</option>
                        <option value="ON">Ontario</option>
                        <option value="PE">Prince Edward Island</option>
                        <option value="QC">Quebec</option>
                        <option value="SK">Saskatchewan</option>
                        <option value="NT">Northwest Territories</option>
                        <option value="NU">Nunavut</option>
                        <option value="YT">Yukon</option>
                      </Form.Control>
                    </Form.Group>
                  </>
                )}

                {formData.creditInfoOption === "licenseAndSin" && (
                  <Form.Group>
                    <Form.Label>SIN</Form.Label>
                    <Form.Control
                      type="text"
                      name="licenseAndSin.sin"
                      value={formData.licenseAndSin.sin}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                )}

                {(formData.creditInfoOption === "licenseAndBankInfo" ||
                  formData.creditInfoOption === "photoIdSinBankInfo") && (
                  <>
                    {formData.creditInfoOption === "photoIdSinBankInfo" && (
                      <>
                        <Form.Group>
                          <Form.Label>Photo ID</Form.Label>
                          <Form.Control
                            type="file"
                            name="photoIdSinBankInfo.photoId"
                            onChange={handleFileChange}
                            required
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>SIN</Form.Label>
                          <Form.Control
                            type="text"
                            name="sin"
                            value={formData.photoIdSinBankInfo.sin}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>
                      </>
                    )}

                    <Form.Group>
                      <Form.Label>Bank Institution Number</Form.Label>
                      <Form.Control
                        type="text"
                        name="photoIdSinBankInfo.bankinfo.bankInstitutionNumber"
                        value={
                          formData.photoIdSinBankInfo.bankinfo
                            .bankInstitutionNumber
                        }
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Bank Transit Number</Form.Label>
                      <Form.Control
                        type="text"
                        name="photoIdSinBankInfo.bankinfo.bankTransitNumber"
                        value={
                          formData.photoIdSinBankInfo.bankinfo.bankTransitNumber
                        }
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Bank Account Number</Form.Label>
                      <Form.Control
                        type="text"
                        name="photoIdSinBankInfo.bankinfo.bankAccountNumber"
                        value={
                          formData.photoIdSinBankInfo.bankinfo.bankAccountNumber
                        }
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Void Cheque</Form.Label>
                      <Form.Control
                        type="file"
                        name="voidCheque"
                        onChange={handleFileChange}
                        required
                      />
                    </Form.Group>
                  </>
                )}
              </div>
            )}
            {/* BANK STATEMENTS & CREDIT INFO If Order Type Yes */}
            {formData.orderType === "Yes" && (
              <div
                style={{
                  backgroundColor: "#fff",
                  padding: "20px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "5px",
                  marginBottom: "20px",
                }}
              >
                <h5>BANK STATEMENTS & CREDIT INFO</h5>
                {/* Bank Statements */}
                {[1, 2, 3].map((index) => (
                  <Form.Group
                    controlId={`formBankStatement${index}`}
                    key={index}
                  >
                    <Form.Label>Bank Statement {index}</Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(e) => handleBankStatementChange(e, index)}
                    />
                  </Form.Group>
                ))}

                {/* Address Selection */}
                <Form.Group>
                  <Form.Label>
                    Are the bank statements' address same as the business
                    address?
                  </Form.Label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Form.Check
                      type="radio"
                      label="Yes"
                      name="isBankSameAddress"
                      value="Yes"
                      checked={formData.isBankSameAddress === "Yes"}
                      onChange={handleChange}
                      style={{ marginRight: "10px" }}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      name="isBankSameAddress"
                      value="No"
                      checked={formData.isBankSameAddress === "No"}
                      onChange={handleChange}
                    />
                  </div>
                </Form.Group>
                {/* Bank Statement Address */}
                {formData.isBankSameAddress === "No" && (
                  <Row>
                    <Form.Group controlId="formNumberStreet">
                      <Form.Label>Number & Street</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Number & Street"
                        name="businessBankAddress.bankAddress.street"
                        value={formData.businessBankAddress.bankAddress.street}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Col>
                      <Form.Group controlId="formAptSuite">
                        <Form.Label>Apt/Suite</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Apt/Suite"
                          name="businessBankAddress.bankAddress.aptSuite"
                          value={
                            formData.businessBankAddress.bankAddress.aptSuite
                          }
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="formCity">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="City"
                          name="businessBankAddress.bankAddress.city"
                          value={formData.businessBankAddress.bankAddress.city}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Row>
                      <Col>
                        <Form.Group controlId="formProvince">
                          <Form.Label>Province</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Province"
                            name="businessBankAddress.bankAddress.province"
                            value={
                              formData.businessBankAddress.bankAddress.province
                            }
                            onChange={handleChange}
                          />
                        <option value="" disabled>
                          Province
                        </option>
                        <option value="Alberta">Alberta</option>
                        <option value="BC">British Columbia</option>
                        <option value="MB">Manitoba</option>
                        <option value="NB">New Brunswick</option>
                        <option value="NL">Newfoundland and Labrador</option>
                        <option value="NS">Nova Scotia</option>
                        <option value="ON">Ontario</option>
                        <option value="PE">Prince Edward Island</option>
                        <option value="QC">Quebec</option>
                        <option value="SK">Saskatchewan</option>
                        <option value="NT">Northwest Territories</option>
                        <option value="NU">Nunavut</option>
                        <option value="YT">Yukon</option>

                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="formPostalCode">
                          <Form.Label>Postal Code</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Postal Code"
                            name="businessBankAddress.bankAddress.postalCode"
                            value={
                              formData.businessBankAddress.bankAddress
                                .postalCode
                            }
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Row>
                )}

                {/* Articles of Incorporation */}
                <Form.Group controlId="formArticlesOfIncorporation">
                  <Form.Label>Articles of Incorporation</Form.Label>
                  <Form.Control
                    type="file"
                    name="articleofIncorporation"
                    value={formData.articleofIncorporation}
                    onChange={handleFileChange}
                  />
                </Form.Group>

                {/* Trade References */}
                {formData.tradeReferences.map((reference, index) => (
                  <div key={index}>
                    <h6>Trade Reference {index + 1}</h6>
                    <Form.Group controlId={`formCompanyName${index}`}>
                      <Form.Label>Company Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Company Name"
                        name={`tradeReferences[${index}].companyName`}
                        value={reference.companyName}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group controlId={`formContactName${index}`}>
                      <Form.Label>Contact Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Contact Name"
                        name={`tradeReferences[${index}].contactName`}
                        value={reference.contactName}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group controlId={`formContactNumber${index}`}>
                      <Form.Label>Contact Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Contact Number"
                        name={`tradeReferences[${index}].contactNumber`}
                        value={reference.contactNumber}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                ))}
              </div>
            )}
            <div
              style={{
                backgroundColor: "#fff",
                padding: "20px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "5px",
              }}
            >
              <h5>NOTES</h5>
              <Form.Group>
                <Form.Label>Notes from dealer</Form.Label>
                <Form.Control
                  as="textarea"
                  name="dealerNotes"
                  value={formData.dealerNotes}
                  onChange={handleChange}
                  placeholder="Notes here"
                  style={{ height: "70px", backgroundColor: "#e6f0ff" }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>source</Form.Label>
                <Form.Control
                  type="text"
                  name="source"
                  value={formData.source}
                  onChange={handleChange}
                  placeholder="source"
                  style={{ height: "70px", backgroundColor: "#e6f0ff" }}
                />
              </Form.Group>
              <Button
                variant="secondary"
                style={{ marginRight: "10px" }}
                // onChange={resetForm()}
              >
                Reset Form
              </Button>
              <Button type="submit" variant="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
    <Footer/>
    </>
  );
};

export default RogerMobility;
