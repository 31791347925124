import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import Footer from '../../layout/Footer'
const RogerForm = () => {
  const [orderType, setOrderType] = useState("");
  const [customerType, setCustomerType] = useState("");
  const [porting, setPorting] = useState(null);
  const [extraDetails, setExtraDetails] = useState({
    extraBoxes: false,
    creditOffered: false,
    extraChannels: false,
  });
  const [serviceType, setServiceType] = useState({
    homePhone: {
      selected: false,
      package: "",
    },
    internet: {
      selected: false,
      package: "",
    },
    tv: {
      selected: false,
      package: "",
    },
  });

  const [idType, setIdType] = useState("");
  const [formData, setFormData] = useState({
    formType:"Rogers",
    existCustomerName: "",
    existCustomerPhone: "",
    existCustomerEmail: "",
    existCancellationId: "",
    dob: "",
    idNumber: "",
    idExpiryDate: "",
    customerName: "",
    customerEmail: "",
    customerAddress: "",
    cellNumber: "",
    portingNumber: "",
    providerName: "",
    accountNumber: "",
    mainNumber: "",
    extraChannelsDetails: "",
    totalCreditOffered: "",
    extraBoxesQuality: "",
    totalPriceOffered: "",
    totaloffered: "",
    preferredInstallationDate: "",
    agentName: "",
    orderNote: "",
    clientName:"",
    installationDate:"",
    timeslot:"",
    status:"received",
    source:"",
    payment:"",
  });

  useEffect(() => {
    if (orderType !== "regularWithId") {
      setIdType(null); 
      setFormData((prevData) => {
        const updatedData = { ...prevData };
        delete updatedData.idType;
        updatedData.idNumber = "";
        updatedData.idExpiryDate = "";
        return updatedData;
      });
    }
  }, [orderType]);
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePortingChange = (e) => {
    setPorting(e.target.value);
  };

  const handleServiceTypeChange = (type, selected, value) => {
    setServiceType((prev) => ({
      ...prev,
      [type]: {
        selected: selected !== undefined ? selected : prev[type].selected,
        package:
          selected === false
            ? ""
            : value !== undefined
            ? value
            : prev[type].package,
      },
    }));
  };

  const handleExtraDetailChange = (e) => {
    setExtraDetails((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.checked,
    }));
  };
  const handleRogersSubmit = async (e) => {
    e.preventDefault();
    const centerName = localStorage.getItem("username") || "";
    const dataToSubmit = {
      ...formData,
      orderType,
      serviceType,
      customerType,
      centerName:centerName,
      installationDate:"",
      timeslot:"",
      payment:"",
      idType,
      extraDetails: {
        extraChannelsDetails: formData.extraChannelsDetails,
        totalCreditOffered: formData.totalCreditOffered,
        extraBoxesQuality: formData.extraBoxesQuality,
      },
    };
    console.log("Rogers Form Data to submit", dataToSubmit);
    try {
      await axios.post("/api/RogerForm", dataToSubmit);
      toast.success("RogerForm submitted successfully!", {
        position: "top-right",
      });
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(`Submission failed: ${errorMessage}`, {
        position: "top-right",
      });
    }
  };

  return (
    <>
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-4 mb-3">
          <h5>Select Order</h5>
          <label htmlFor="orderType">Order Type</label>
          <select
            id="orderType"
            className="form-control"
            value={orderType}
            onChange={(e) => setOrderType(e.target.value)}
            style={{ color: orderType === "" ? "red" : "inherit" }}
          >
            <option value="" disabled>
              Select Order Type
            </option>
            <option value="regularWithId">Regular with ID</option>
            <option value="regularWithoutId">Regular without ID</option>
          </select>
          <label htmlFor="customerType">Customer Type</label>
          <select
            id="customerType"
            className="form-control"
            value={customerType}
            onChange={(e) => setCustomerType(e.target.value)}
            style={{ color: customerType === "" ? "red" : "inherit" }}
          >
            <option value="" disabled>
              Select Customer Type
            </option>
            <option value="new">New</option>
            <option value="existing">Existing</option>
          </select>
        </div>

        <div className="col-md-4 mb-3">
          {customerType === "existing" && (
            <div className="row">
              <h5>Previous Customer Information</h5>
              <div className="col-md-6 mb-3">
                <label htmlFor="existCustomerName">Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="existCustomerName"
                  placeholder="Customer Name"
                  value={formData.existCustomerName}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      existCustomerName: e.target.value,
                    })
                  }
                  style={{ color: "red" }}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="existCustomerPhone">Phone No</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Customer Phone No"
                  name="existCustomerPhone"
                  value={formData.existCustomerPhone}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      existCustomerPhone: e.target.value,
                    })
                  }
                  style={{ color: "red" }}
                />
              </div>
              <div className="col-md-6 mt-2 mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Customer Email"
                  name="existCustomerEmail"
                  value={formData.existCustomerEmail}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      existCustomerEmail: e.target.value,
                    })
                  }
                  style={{ color: "red" }}
                />
              </div>
              <div className="col-md-6 mt-2 mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Cancellation/Interaction ID"
                  value={formData.existCancellationId}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      existCancellationId: e.target.value,
                    })
                  }
                  style={{ color: "red" }}
                />
              </div>
            </div>
          )}
        </div>

        <div className="col-md-4 mb-3">
          <h5>Credit Information</h5>
          <label htmlFor="dob">Date Of Birth</label>
          <input
            type="date"
            id="dob"
            className="form-control"
            placeholder="Date of Birth"
            value={formData.dob}
            onChange={(e) => setFormData({ ...formData, dob: e.target.value })}
            style={{ color: "red" }}
          />

          {orderType === "regularWithId" && (
            <select
              className="form-control mt-2"
              value={idType}
              onChange={(e) => setIdType(e.target.value)}
              style={{ color: idType === "" ? "red" : "inherit" }}
            >
              <option value="" disabled>
                Select ID Type
              </option>
              <option value="passport">Passport</option>
              <option value="drivingLicense">Driving License</option>
              <option value="sin">SIN</option>
            </select>
          )}

          {orderType === "regularWithId" && idType && (
            <>
              <input
                type="text"
                className="form-control mt-2"
                placeholder={`${
                  idType.charAt(0).toUpperCase() + idType.slice(1)
                } No.`}
                value={formData.idNumber}
                onChange={(e) =>
                  setFormData({ ...formData, idNumber: e.target.value })
                }
                style={{ color: "red" }}
              />
              {idType !== "sin" && (
                <>
                  <label>Expiry Date</label>
                  <input
                    type="date"
                    className="form-control mt-2"
                    placeholder="ID Expiry Date"
                    style={{ color: "red" }}
                    value={formData.idExpiryDate}
                    onChange={(e) =>
                      setFormData({ ...formData, idExpiryDate: e.target.value })
                    }
                  />
                </>
              )}
            </>
          )}
        </div>
        <Container fluid style={{ padding: "20px" }}>
          <ToastContainer />
          <Row>
            <Col md={3} style={{ borderRight: "1px solid #ddd" }}>
              <h5>Customer Details</h5>
              <Form.Group controlId="customerName">
                <Form.Label>Customer Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter customer name"
                  name="customerName"
                  value={formData.customerName}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formCustomerEmail">
                <Form.Label>Customer Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter customer email"
                  name="customerEmail"
                  value={formData.customerEmail}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formCustomerAddress">
                <Form.Label>Customer Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter customer address"
                  name="customerAddress"
                  value={formData.customerAddress}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col>

            <Col md={3} style={{ borderRight: "1px solid #ddd" }}>
              <h5>Porting Information</h5>
              <Form.Label>
                Do Customer Want to port the same phone number?
              </Form.Label>
              <Form.Check
                type="radio"
                label="Yes"
                name="porting"
                value="yes"
                onChange={handlePortingChange}
              />
              <Form.Check
                type="radio"
                label="No"
                name="porting"
                value="no"
                onChange={handlePortingChange}
                defaultChecked
              />

              {porting === "yes" && (
                <>
                  <Form.Group controlId="portingNumber">
                    <Form.Label>Porting Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter porting number"
                      name="portingNumber"
                      value={formData.portingNumber}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="providerName">
                    <Form.Label>Provider Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Provider Name"
                      name="providerName"
                      value={formData.providerName}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="accountNumber">
                    <Form.Label>Account Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Account Number"
                      name="accountNumber"
                      value={formData.accountNumber}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="cellNumber">
                    <Form.Label>Cell Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Cell Number"
                      name="cellNumber"
                      value={formData.cellNumber}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </>
              )}
              {porting === "no" && (
                <Form.Group controlId="mainNumber">
                  <Form.Label>Main Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Main Number"
                    name="mainNumber"
                    value={formData.mainNumber}
                    onChange={handleInputChange}
                  />
                  <Form.Label>Cell Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Cell Number"
                    name="cellNumber"
                    value={formData.cellNumber}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              )}
            </Col>

            <Col md={3} style={{ borderRight: "1px solid #ddd" }}>
              <h5>Service Type</h5>

              <Form.Check
                type="checkbox"
                label="Home Phone"
                name="homePhone"
                checked={serviceType.homePhone.selected}
                onChange={() =>
                  handleServiceTypeChange(
                    "homePhone",
                    !serviceType.homePhone.selected
                  )
                }
              />
              {serviceType.homePhone.selected && (
                <Form.Group controlId="homePhone">
                  <Form.Label>Home Phone Packages</Form.Label>
                  <Form.Control
                    as="select"
                    value={serviceType.homePhone.package}
                    onChange={(e) =>
                      handleServiceTypeChange("homePhone", true, e.target.value)
                    }
                  >
                    <option>Select package</option>
                    <option>US and Canada Wide Calling</option>
                  </Form.Control>
                </Form.Group>
              )}

              {/* Internet Checkbox */}
              <Form.Check
                type="checkbox"
                label="Internet"
                name="internet"
                checked={serviceType.internet.selected}
                onChange={() =>
                  handleServiceTypeChange(
                    "internet",
                    !serviceType.internet.selected
                  )
                }
              />
              {serviceType.internet.selected && (
                <Form.Group controlId="InternetPackages">
                  <Form.Label>Internet Packages</Form.Label>
                  <Form.Control
                    as="select"
                    value={serviceType.internet.package}
                    onChange={(e) =>
                      handleServiceTypeChange("internet", true, e.target.value)
                    }
                  >
                    <option>Select package</option>
                    <option>Fibe 50</option>
                    <option>Fibe 100</option>
                    <option>Fibe 150</option>
                    <option>Fibe 500</option>
                  </Form.Control>
                </Form.Group>
              )}

              {/* TV Checkbox */}
              <Form.Check
                type="checkbox"
                label="TV"
                name="tv"
                checked={serviceType.tv.selected}
                onChange={() =>
                  handleServiceTypeChange("tv", !serviceType.tv.selected)
                }
              />
              {serviceType.tv.selected && (
                <Form.Group controlId="TvPackages">
                  <Form.Label>TV Packages</Form.Label>
                  <Form.Control
                    as="select"
                    value={serviceType.tv.package}
                    onChange={(e) =>
                      handleServiceTypeChange("tv", true, e.target.value)
                    }
                  >
                    <option>Select package</option>
                    <option>Fibe TV</option>
                    <option>Starter TV</option>
                  </Form.Control>
                </Form.Group>
              )}
            </Col>

            <Col md={3}>
              <h5>Extra Details</h5>
              <Form.Check
                type="checkbox"
                label="Extra Boxes"
                name="extraBoxes"
                checked={extraDetails.extraBoxes}
                onChange={handleExtraDetailChange}
              />
              {extraDetails.extraBoxes && (
                <>
                  <Form.Group controlId="extraBoxesQuality">
                    <Form.Label>Extra Boxes Quality</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Quality"
                      name="extraBoxesQuality"
                      value={formData.extraBoxesQuality}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </>
              )}

              <Form.Check
                type="checkbox"
                label="Credit Offered"
                name="creditOffered"
                checked={extraDetails.creditOffered}
                onChange={handleExtraDetailChange}
              />
              {extraDetails.creditOffered && (
                <Form.Group controlId="totalCreditOffered">
                  <Form.Label>Credit Details</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Total Credit Offered"
                    name="totalCreditOffered"
                    value={formData.totalCreditOffered}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              )}

              <Form.Check
                type="checkbox"
                label="Extra Channels"
                name="extraChannels"
                checked={extraDetails.extraChannels}
                onChange={handleExtraDetailChange}
              />
              {extraDetails.extraChannels && (
                <Form.Group controlId="extraChannelsDetails">
                  <Form.Label>Extra Channels Details</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Details"
                    name="extraChannelsDetails"
                    value={formData.extraChannelsDetails}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              )}
              <Form.Group controlId="totaloffered">
                <Form.Label>Total Offered</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Total Offered"
                  name="totaloffered"
                  value={formData.totaloffered}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <hr style={{ margin: "20px 0" }} />
          <Row className="mt-3">
            <Col>
              <Form.Group controlId="totalPriceOffered">
                <Form.Label>Total Price Offered</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Total Price"
                  name="totalPriceOffered"
                  value={formData.totalPriceOffered}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="preferredInstallationDate">
                <Form.Label>Preferred Installation Date</Form.Label>
                <Form.Control
                  type="date"
                  name="preferredInstallationDate"
                  value={formData.preferredInstallationDate}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="agentName">
                <Form.Label>Agent Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter agent name"
                  name="agentName"
                  value={formData.agentName}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="source">
                <Form.Label>Source</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Source"
                  name="source"
                  value={formData.source}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="orderNote">
                <Form.Label>Note on Order</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={1}
                  placeholder="Enter notes"
                  name="orderNote"
                  value={formData.orderNote}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="row">
        <div className="col-md-12 mt-3">
          <button
            className="btn btn-primary"
            onClick={handleRogersSubmit}
            style={{ backgroundColor: "#0d6efd" }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default RogerForm;
