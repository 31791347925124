import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend, Title);

const SalesTargetChart = ({
  completedOrders,
  toDoOrders,
  bellTarget,
  rogersTarget,
  rogersMobilityTarget,
}) => {
  const chartData = {
    labels: [
      "Completed Orders",
      "Need to Do",
      "Bell Target",
      "Rogers Target",
      "Rogers Mobility Target",
    ],
    datasets: [
      {
        label: "Sales Target",
        data: [
          completedOrders,
          toDoOrders,
          bellTarget,
          rogersTarget,
          rogersMobilityTarget,
        ],
        backgroundColor: [
          "#4CAF50",
          "#FFCE56",
          "#8E44AD",
          "#3498DB",
          "#E67E22",
        ],
        hoverBackgroundColor: [
          "#3E8C43",
          "#E6B947",
          "#7D3C98",
          "#2980B9",
          "#D35400",
        ],
        borderWidth: 2,
        cutout: "85%",
      },
    ],
  };

  const totalTarget = bellTarget + rogersTarget + rogersMobilityTarget;

  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
  };

  return (
    <div
      style={{
        width: '300px', 
        height: '500px', 
        backgroundColor: "#1E1E2F",
        color: "#fff",
        padding: "15px",
        borderRadius: "10px",
        boxShadow: "0 4px 10px rgba(0,0,0,0.5)",
        textAlign: "center",
      }}
    >
      <h3
        style={{
          color: "#fff",
          fontSize: "14px",
          fontWeight: "bold",
          marginBottom: "20px",
        }}
      >
        Sales Target
      </h3>
      <div
        style={{
          position: "relative",
          height: '150px', 
          width: '150px', 
          margin: "0 auto",
        }}
      >
        <Doughnut data={chartData} options={chartOptions} />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ fontWeight: "normal", color: "#4CAF50", fontSize:'12px'}}>
              Total Target:
            </span>
            <span style={{ fontSize: "1.2rem", color: "#fff" }}>
              {totalTarget}
            </span>
          </div>
        </div>
      </div>

      <div
        style={{
          marginTop: "20px",
          color: "#fff",
          fontSize: "12px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <p style={{ margin: 0, fontWeight: "bold" }}>Bell Target</p>
          <p>{bellTarget}</p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <p style={{ margin: 0, fontWeight: "bold" }}>Rogers Target</p>
          <p>{rogersTarget}</p>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p style={{ margin: 0, fontWeight: "bold" }}>
            Rogers Mobility Target
          </p>
          <p>{rogersMobilityTarget}</p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p style={{ margin: 0, fontWeight: "bold" }}>Completed Orders</p>
          <p>{completedOrders}</p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p style={{ margin: 0, fontWeight: "bold" }}>Need to Do</p>
          <p>{toDoOrders}</p>
        </div>
      </div>
    </div>
  );
};

export default SalesTargetChart;
