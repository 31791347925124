import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend, Title);

const OrderRatiosChart = ({
  received,
  submitted,
  booked,
  completed,
  cancelled,
  issued,
}) => {
  const chartData = {
    labels: [
      "Received",
      "Submitted",
      "Booked",
      "Completed",
      "Cancelled",
      "Issued",
    ],
    datasets: [
      {
        data: [received, submitted, booked, completed, cancelled, issued],
        backgroundColor: [
          "#34A853",
          "#FBBC05",
          "#4285F4",
          "#8BC34A",
          "#EA4335",
          "#B0BEC5",
        ],
        hoverBackgroundColor: [
          "#2C8C46",
          "#E8A700",
          "#3367D6",
          "#7CAB3E",
          "#D92B26",
          "#9FA6AB",
        ],
        borderWidth: 2,
        cutout: "85%", // keeps the donut shape
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: false, // Hide default legend
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
  };

  return (
    <div
      style={{
        width: '300px', 
        height: '500px', 
        backgroundColor: "#1E1E2F",
        color: "#fff",
        padding: '15px',
        borderRadius: "10px",
        boxShadow: "0 4px 10px rgba(0,0,0,0.5)",
        textAlign: "center",
      }}
    >
      <h3
        style={{
          color: "#fff",
          fontSize: "14px",
          fontWeight: "bold",
          marginBottom: "20px",
        }}
      >
        Order Ratios
      </h3>
      <div
        style={{
          position: "relative",
          height: '150px', 
          width: '150px', 
          margin: "0 auto",
        }}
      >
        <Doughnut data={chartData} options={chartOptions} />
      </div>
        
      <div
        style={{
          marginTop: "20px",
          color: "#fff",
          fontSize: "12px",
          textAlign: "left",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <span style={{ fontWeight: "bold", color: "#34A853" }}>Received:</span>
          <span>{received}%</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <span style={{ fontWeight: "bold", color: "#FBBC05" }}>Submitted:</span>
          <span>{submitted}%</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <span style={{ fontWeight: "bold", color: "#4285F4" }}>Booked:</span>
          <span>{booked}%</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <span style={{ fontWeight: "bold", color: "#8BC34A" }}>Completed:</span>
          <span>{completed}%</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <span style={{ fontWeight: "bold", color: "#EA4335" }}>Cancelled:</span>
          <span>{cancelled}%</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span style={{ fontWeight: "bold", color: "#B0BEC5" }}>Issued:</span>
          <span>{issued}%</span>
        </div>
      </div>
    </div>
  );
};

export default OrderRatiosChart;
