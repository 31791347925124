import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaCopy, FaEye } from "react-icons/fa";

const BellModal = ({ order }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleCopy = () => {
    const content = `
      Customer Name: ${order.customerName}
      Email: ${order.customerEmail}
      Address: ${order.customerAddress}
      Cell Number: ${order.cellNumber}
      Porting: ${order.porting}
      Main Number: ${order.mainNumber}
      Service Type: ${order.serviceType?.homePhone?.package}, ${
      order.serviceType?.internet?.package
    }, ${order.serviceType?.tv?.package}
      Extra Details: ${order.extraDetails?.extraBoxesQuality}, ${
      order.extraDetails?.totalCreditOffered
    } credit, ${order.extraDetails?.extraChannelsDetails}
      Price Offered: ${order.totalPriceOffered}
      Preferred Installation Date: ${new Date(
        order.preferredInstallationDate
      ).toLocaleDateString()}
      Agent Name: ${order.agentName}
      Notes: ${order.orderNote}
    `.trim();

    navigator.clipboard.writeText(content);
    alert("Copied to clipboard!");
  };

  const renderField = (fields) => {
    return (
      <div className="row mb-2">
        {fields
          .filter(([label, value]) => value)
          .map(([label, value], index) => (
            <div className="col-md-4" key={index}>
              <strong>{label}:</strong> {value}
            </div>
          ))}
      </div>
    );
  };

  return (
    <>
      <FaEye style={{ cursor: "pointer" }} onClick={handleShow} />
      <Modal
        show={show}
        onHide={handleClose}
        style={{ zIndex: 1050 }}
        backdropStyle={{ zIndex: 1049 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            {/* Bell Form */}
            {renderField([
              ["Customer Name", order.customerName],
              ["Email", order.customerEmail],
              ["Address", order.customerAddress],
            ])}
            {renderField([
              ["Cell Number", order.cellNumber],
              ["Porting", order.porting],
              ["Main Number", order.mainNumber],
            ])}

            {order.serviceType && (
              <div className="row">
                <div className="col-md-12">
                  <strong>Service Type:</strong>
                  <ul>
                    {order.serviceType.homePhone?.package && (
                      <li>{order.serviceType.homePhone.package}</li>
                    )}
                    {order.serviceType.internet?.package && (
                      <li>{order.serviceType.internet.package}</li>
                    )}
                    {order.serviceType.tv?.package && (
                      <li>{order.serviceType.tv.package}</li>
                    )}
                  </ul>
                </div>
              </div>
            )}

            {renderField([
              ["Extra Boxes Quality", order.extraDetails?.extraBoxesQuality],
              [
                "Total Credit Offered",
                `$${order.extraDetails?.totalCreditOffered}`,
              ],
              ["Price Offered", `$${order.totalPriceOffered}`],
            ])}

            {/* Rogers Form */}
            {renderField([
              ["Agent Name", order.agentName],
              [
                "Preferred Installation Date",
                new Date(order.preferredInstallationDate).toLocaleDateString(),
              ],
              ["Order Note", order.orderNote],
            ])}
            {renderField([
              ["installationDate", order.installationDate],
              ["status", order.status],
              ["orderType", order.orderType],
            ])}
            {renderField([
              ["Customer Type", order.customerType],
              ["existCustomerName", order.existCustomerName],
              ["existCustomerPhone", order.existCustomerPhone],
            ])}
            {renderField([
              ["existCustomerEmail", order.existCustomerEmail],
              ["existCancellationId", order.existCancellationId],
              ["dob", order.dob],
            ])}
            {renderField([
              ["id Type", order.idType],
              ["idNumber", order.idNumber],
              ["idExpiryDate", order.idExpiryDate],
            ])}
            {/* Mobility Form */}
            {renderField([
              ["formType", order.formType],
              ["centerName", order.centerName],
              ["orderType", order.orderType],
            ])}
            {/* personalDetails */}
            {renderField([
              ["Full Name", order.personalDetails?.fullName ],
              ["dateOfBirth", order.personalDetails?.dateOfBirth],
              ["contactNumber", order.personalDetails?.contactNumber],
              ["email", order.personalDetails?.email],
              ["email", order.rogerInternectAcc],
            ])}

            {renderField([
              ["street", order.personalDetails?.address?.street],
              ["aptSuite", order.personalDetails?.address?.aptSuite],
              ["city", order.personalDetails?.address?.city],
              ["province", order.personalDetails?.address?.province],
              [
                "postalCode",
                order.personalDetails?.address?.postalCode,
              ],
            ])}
          {/* businessDetails */}
            {renderField([
              ["Name", order.businessDetails?.name],
              ["authorizedUserName", order.businessDetails?.authorizedUserName],
              ["authorizedUserPhone", order.businessDetails?.authorizedUserPhone],
              ["authorizedUserEmail", order.businessDetails?.authorizedUserEmail],
              ["businessPhone", order.businessDetails?.businessPhone],
              ["businessEmail", order.businessDetails?.businessEmail],
              ["authorizedUserPhotoId", order.businessDetails?.authorizedUserPhotoId],
              ["phone", order.businessDetails?.phone],
              ["email", order.businessDetails?.email],
            ])}

            {renderField([
              ["id Type", order.phone],
              ["idNumber", order.idNumber],
              ["idExpiryDate", order.idExpiryDate],
            ])}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleCopy}>
            <FaCopy /> Copy
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BellModal;
