import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "react-bootstrap";
import Footer from '../../layout/Footer'
const BellForm = () => {
  const [porting, setPorting] = useState(null);
  const [serviceType, setServiceType] = useState({
    homePhone: {
      selected: false,
      package: "",
    },
    internet: {
      selected: false,
      package: "",
    },
    tv: {
      selected: false,
      package: "",
    },
  });

  const [extraDetails, setExtraDetails] = useState({
    extraBoxes: false,
    creditOffered: false,
    extraChannels: false,
  });
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    formType: "",
    centerName: "",
    customerName: "",
    customerEmail: "",
    customerAddress: "",
    cellNumber: "",
    portingNumber: "",
    providerName: "",
    accountNumber: "",
    mainNumber: "",
    extraChannelsDetails: "",
    totalCreditOffered: "",
    extraBoxesQuality: "",
    totalPriceOffered: "",
    totaloffered: "",
    preferredInstallationDate: "",
    installationDate: "",
    timeslot: "",
    source:"",
    agentName: "",
    orderNote: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePortingChange = (e) => {
    setPorting(e.target.value);
  };

  const handleServiceTypeChange = (type, selected, value) => {
    setServiceType((prev) => ({
      ...prev,
      [type]: {
        selected: selected !== undefined ? selected : prev[type].selected,
        package: value !== undefined ? value : prev[type].package,
      },
    }));
  };

  const handleExtraDetailChange = (e) => {
    setExtraDetails((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.checked,
    }));
  };

  const handleBellFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const centerName = localStorage.getItem("username") || "";
    const dataToSubmit = {
      ...formData,
      centerName: centerName,
      serviceType,
      porting,
      extraDetails: {
        extraChannelsDetails: extraDetails.extraChannels
          ? formData.extraChannelsDetails
          : "",
        totalCreditOffered: extraDetails.creditOffered
          ? formData.totalCreditOffered
          : "",
        extraBoxesQuality: extraDetails.extraBoxes
          ? formData.extraBoxesQuality
          : "",
      },
    };
    try {
      await axios.post("/api/BellForm", dataToSubmit);
      setTimeout(() => {
        setLoading(false);
        toast.success("Form submitted successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }, 2000);
      setFormData({
        formType:"",
        centerName: "",
        customerName: "",
        customerEmail: "",
        customerAddress: "",
        cellNumber: "",
        portingNumber: "",
        providerName: "",
        accountNumber: "",
        mainNumber: "",
        extraChannelsDetails: "",
        totalCreditOffered: "",
        extraBoxesQuality: "",
        totalPriceOffered: "",
        totaloffered: "",
        preferredInstallationDate: "",
        installationDate: "",
        timeslot: "",
        source:"",
        agentName: "",
        orderNote: "",
      });

      setServiceType({
        homePhone: { selected: false, package: "" },
        internet: { selected: false, package: "" },
        tv: { selected: false, package: "" },
      });

      setExtraDetails({
        extraBoxes: false,
        creditOffered: false,
        extraChannels: false,
      });

      setPorting(null);
    } catch (error) {
      setLoading(false);
      const errorMessage =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      toast.error("Form submission failed: " + errorMessage, {
        position: "top-center",
      });
    }
  };

  return (
    <div>
    <Container fluid style={{ padding: "20px" }}>
      <ToastContainer />
      <Row>
      <Col md={3} style={{ borderRight: "1px solid #ddd" }}>
      <Form.Group controlId="formType">
            <Form.Label>Form Type</Form.Label>
            <Form.Control
              as="select" 
              id="formType"
              name="formType"
              value={formData.formType}
              onChange={handleInputChange}
              required
            >
              <option value="" disabled>Select Type</option>
              <option value="Bell">Bell</option>
              <option value="Distributal">Distributal</option>
              <option value="Telus">Telus</option>
              <option value="Cogeco">Cogeco</option>
              <option value="Shaw ">Shaw</option>
            </Form.Control>
            {formData.formType === "" && (
              <span className="text-danger">Please must select a type</span>
            )}
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={3} style={{ borderRight: "1px solid #ddd" }}>
          <h5>Customer Details</h5>
          <Form.Group controlId="customerName">
            <Form.Label>Customer Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter customer name"
              name="customerName"
              value={formData.customerName}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formCustomerEmail">
            <Form.Label>Customer Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter customer email"
              name="customerEmail"
              value={formData.customerEmail}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formCustomerAddress">
            <Form.Label>Customer Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter customer address"
              name="customerAddress"
              value={formData.customerAddress}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
        </Col>

        <Col md={3} style={{ borderRight: "1px solid #ddd" }}>
          <h5>Porting Information</h5>
          <Form.Label>
            Do Customer Want to port the same phone number?
          </Form.Label>
          <Form.Check
            type="radio"
            label="Yes"
            name="porting"
            value="yes"
            onChange={handlePortingChange}
          />
          <Form.Check
            type="radio"
            label="No"
            name="porting"
            value="no"
            onChange={handlePortingChange}
            defaultChecked
          />

          {porting === "yes" && (
            <>
              <Form.Group controlId="portingNumber">
                <Form.Label>Porting Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter porting number"
                  name="portingNumber"
                  value={formData.portingNumber}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="providerName">
                <Form.Label>Provider Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Provider Name"
                  name="providerName"
                  value={formData.providerName}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="accountNumber">
                <Form.Label>Account Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Account Number"
                  name="accountNumber"
                  value={formData.accountNumber}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="cellNumber">
                <Form.Label>Cell Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Cell Number"
                  name="cellNumber"
                  value={formData.cellNumber}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </>
          )}
          {porting === "no" && (
            <Form.Group controlId="mainNumber">
              <Form.Label>Main Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Main Number"
                name="mainNumber"
                value={formData.mainNumber}
                onChange={handleInputChange}
              />
              <Form.Label>Cell Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Cell Number"
                name="cellNumber"
                value={formData.cellNumber}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
          )}
        </Col>

        <Col md={3} style={{ borderRight: "1px solid #ddd" }}>
          <h5>Service Type</h5>

          <Form.Check
            type="checkbox"
            label="Home Phone"
            name="homePhone"
            checked={serviceType.homePhone.selected}
            onChange={() =>
              handleServiceTypeChange(
                "homePhone",
                !serviceType.homePhone.selected
              )
            }
          />

          {serviceType.homePhone.selected && (
            <Form.Group controlId="homePhone">
              <Form.Label>Home Phone Packages</Form.Label>
              <Form.Control
                as="select"
                value={serviceType.homePhone.package}
                onChange={(e) =>
                  handleServiceTypeChange("homePhone", true, e.target.value)
                }
              >
                <option>Select package</option>
                <option>US and Canada Wide Calling</option>
              </Form.Control>
            </Form.Group>
          )}

          {/* Internet Checkbox */}
          <Form.Check
            type="checkbox"
            label="Internet"
            name="internet"
            checked={serviceType.internet.selected}
            onChange={() =>
              setServiceType((prev) => ({
                ...prev,
                internet: {
                  selected: !prev.internet.selected,
                  package: prev.internet.package,
                },
              }))
            }
          />
          {serviceType.internet.selected && (
            <Form.Group controlId="InternetPackages">
              <Form.Label>Internet Packages</Form.Label>
              <Form.Control
                as="select"
                value={serviceType.internet.package}
                onChange={(e) =>
                  setServiceType((prev) => ({
                    ...prev,
                    internet: { selected: true, package: e.target.value },
                  }))
                }
              >
                <option>Select package</option>
                <option>Fibe 50</option>
                <option>Fibe 100</option>
                <option>Fibe 150</option>
                <option>Fibe 500</option>
              </Form.Control>
            </Form.Group>
          )}

          {/* TV Checkbox */}
          <Form.Check
            type="checkbox"
            label="TV"
            name="tv"
            checked={serviceType.tv.selected}
            onChange={() =>
              setServiceType((prev) => ({
                ...prev,
                tv: { selected: !prev.tv.selected, package: prev.tv.package },
              }))
            }
          />
          {serviceType.tv.selected && (
            <Form.Group controlId="TvPackages">
              <Form.Label>TV Packages</Form.Label>
              <Form.Control
                as="select"
                value={serviceType.tv.package}
                onChange={(e) =>
                  setServiceType((prev) => ({
                    ...prev,
                    tv: { selected: true, package: e.target.value },
                  }))
                }
              >
                <option>Select package</option>
                <option>Fibe TV</option>
                <option>Starter TV</option>
              </Form.Control>
            </Form.Group>
          )}
        </Col>

        <Col md={3}>
          <h5>Extra Details</h5>
          <Form.Check
            type="checkbox"
            label="Extra Boxes"
            name="extraBoxes"
            checked={extraDetails.extraBoxes}
            onChange={handleExtraDetailChange}
          />
          {extraDetails.extraBoxes && (
            <>
              <Form.Group controlId="extraBoxesQuality">
                <Form.Label>Extra Boxes Quality</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Quality"
                  name="extraBoxesQuality"
                  value={formData.extraBoxesQuality}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </>
          )}

          <Form.Check
            type="checkbox"
            label="Credit Offered"
            name="creditOffered"
            checked={extraDetails.creditOffered}
            onChange={handleExtraDetailChange}
          />
          {extraDetails.creditOffered && (
            <Form.Group controlId="totalCreditOffered">
              <Form.Label>Credit Details</Form.Label>
              <Form.Control
                type="text"
                placeholder="Total Credit Offered"
                name="totalCreditOffered"
                value={formData.totalCreditOffered}
                onChange={handleInputChange}
              />
            </Form.Group>
          )}

          <Form.Check
            type="checkbox"
            label="Extra Channels"
            name="extraChannels"
            checked={extraDetails.extraChannels}
            onChange={handleExtraDetailChange}
          />
          {extraDetails.extraChannels && (
            <Form.Group controlId="extraChannelsDetails">
              <Form.Label>Extra Channels Details</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Details"
                name="extraChannelsDetails"
                value={formData.extraChannelsDetails}
                onChange={handleInputChange}
              />
            </Form.Group>
          )}
          <Form.Group controlId="totaloffered">
            <Form.Label>Total Offered</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Total Offered"
              name="totaloffered"
              value={formData.totaloffered}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <hr style={{ margin: "20px 0" }} />
      <Row className="mt-3">
        <Col>
          <Form.Group controlId="totalPriceOffered">
            <Form.Label>Total Price Offered</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Total Price"
              name="totalPriceOffered"
              value={formData.totalPriceOffered}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="preferredInstallationDate">
            <Form.Label>Preferred Installation Date</Form.Label>
            <Form.Control
              type="date"
              name="preferredInstallationDate"
              value={formData.preferredInstallationDate}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="agentName">
            <Form.Label>Agent Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter agent name"
              name="agentName"
              value={formData.agentName}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="source">
            <Form.Label>Source</Form.Label>
            <Form.Control
              type="text"
              placeholder="Source"
              name="source"
              value={formData.source}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="orderNote">
            <Form.Label>Note on Order</Form.Label>
            <Form.Control
              as="textarea"
              rows={1}
              placeholder="Enter notes"
              name="orderNote"
              value={formData.orderNote}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
      </Row>

      <Button
        variant="primary"
        type="submit"
        style={{ marginTop: "20px" }}
        onClick={handleBellFormSubmit}
        disabled={loading}
      >
        {loading ? (
          <>
            <Spinner
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />{" "}
            Submitting...
          </>
        ) : (
          "Submit"
        )}
      </Button>
    </Container>
    <Footer/>

    </div>
  );
};

export default BellForm;
