import React from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from '../layout/Footer'
const FormCard = ({ title, link, style }) => (
  <Card
    style={{
      width: "18rem",
      marginBottom: "20px",
      marginTop: "40px",
      ...style,
    }}
  >
    <Card.Body>
      <Card.Title>{title}</Card.Title>
      <Button variant="primary" as={Link} to={link}>
        Open Form
      </Button>
    </Card.Body>
  </Card>
);

const FormSelection = () => {
  return (
    <div>
    <Container className="p-5 mb-5">
      <Row className="justify-content-center">
      <h3 style={{ textAlign: 'center' }}>Please Open a form to fill out.</h3>
        <Col md="auto">
          <FormCard
            title="Bell"
            link="/bell-form"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              background: "linear-gradient(135deg, #00c6ff, #0072ff)",
              color: "white",
            }}
          />
        </Col>
        <Col md="auto">
          <FormCard
            title="Rogers"
            link="/rogers-form"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              background: "linear-gradient(135deg, #ff7e5f, #feb47b)",
              color: "white",
            }}
          />
        </Col>
        <Col md="auto">
          <FormCard
            title="Rogers Mobility"
            link="/rogers-mobility-form"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              background: "linear-gradient(135deg, #43cea2, #185a9d)",
              color: "white",
            }}
          />
        </Col>
      </Row>
    </Container>
    <Footer/>
    </div>
  );
};

export default FormSelection;
