import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FaTachometerAlt, FaBox, FaUser, FaSignOutAlt, FaPlus, FaBook, FaMoneyBill} from 'react-icons/fa';
import { IoChatboxSharp } from "react-icons/io5";
import { GiTakeMyMoney } from "react-icons/gi";
const SideBarContainer = styled.div`
  width: 170px; 
  background-color: black;
  top:0;
  position: sticky; 
`;

const StyledLink = styled(Link)`
  display: flex; 
  align-items: center;  
  padding: 10px 20px;
  margin-top:12px; 
  text-decoration: none;
  color: white; 
  font-size:14px;
  transition: background-color 0.3s;
  
  &:hover {
    background-color: orange; 
    border-radius: 10px;
  }

  &.active {
    background-color: purple;
    color: white; 
    border-radius: 10px;
  }

  svg {
    margin-right: 10px; 
  }
`;

const SideBar = () => {
  const location = useLocation(); 
  return (
    <SideBarContainer>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
      <li>
          <StyledLink to="/main-form" className={location.pathname === "/main-form" ? "active" : "" }>
            <FaPlus /> 
            New Order
          </StyledLink>
        </li>
        <li>
          <StyledLink to="/admin/dashboard" className={location.pathname === "/admin/dashboard" ? "active" : "" }>
            <FaTachometerAlt /> 
            Dashboard
          </StyledLink>
        </li>
        <li>
          <StyledLink to="/admin/orders" className={location.pathname === "/admin/orders" ? "active" : ""}>
            <FaBox /> 
            Orders
          </StyledLink>
        </li>
        <li>
          <StyledLink to="/admin/user" className={location.pathname === "/admin/user" ? "active" : ""}>
            <FaUser />
            User
          </StyledLink>
        </li>
        <li>
          <StyledLink to="/admin/setTarget" className={location.pathname === "/admin/setTarget" ? "active" : ""}>
            <FaPlus /> 
            Add Target
          </StyledLink>
          <StyledLink to="/admin/setPrice" className={location.pathname === "/admin/setPrice" ? "active" : ""}>
            <FaMoneyBill /> 
            Set Price
          </StyledLink>
        </li>
        <li>
        <StyledLink to="/admin/chat" className={location.pathname === "/admin/chat" ? "active" : ""}>
            <IoChatboxSharp /> 
            Chat
          </StyledLink>
        </li>
        <li>
        <StyledLink to="/admin/documentation" className={location.pathname === "/admin/documentation" ? "active" : ""}>
            <FaBook /> 
            Documentation
          </StyledLink>
        </li>
        <li>
        <StyledLink to="/admin/Profit" className={location.pathname === "/admin/Profit" ? "active" : ""}>
            <GiTakeMyMoney /> 
            Profit
          </StyledLink>
        </li>
        <li>
          <StyledLink to="/admin/logout" className={location.pathname === "/admin/logout" ? "active" : ""}>
            <FaSignOutAlt /> 
            Logout
          </StyledLink>
        </li>
      </ul>
    </SideBarContainer>
  );
};

export default SideBar;
