import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import RegisterForm from "./Components/auth/RegisterForm/RegisterForm";
import LoginForm from "./Components/auth/LoginForm/LoginForm";
import Form from "./Components/Form/Forms";
import Logout from "./Components/auth/Logout/Logout";
import Dashboard from "./Components/Portal/Dashboard/Dashboard";
import User from "./Components/Portal/User/User";
import Portal from "./Components/MainPortal/MainPortal";
import Orders from "./Components/Portal/Order/Orders";
import Header from "./Components/layout/Header";
import BellForm from "./Components/Form/BellForm/BellForm";
import RogerForm from "./Components/Form/RogerForm/RogerForm";
import RogerMobility from "./Components/Form/RogerMobilityForm/RogerMobility";
import "./App.css";
import TargetForm from "./Components/Form/TargetForm/TargetForm";
import ChatForm from "./Components/Portal/Chat/ChatForm";
import Documentation from "./Components/Portal/Documentation/Documentation";
import Profit from "./Components/Portal/Profit/Profit";
import PriceForm from "./Components/Form/setPriceForm/SetPrice";
import { ToastContainer } from "react-toastify";
function AppContent() {
  const location = useLocation();
  const isAdminPath = location.pathname.startsWith("/admin");
  const [user, setUser] = useState(null);
  const role = localStorage.getItem('role')
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      setUser(storedUser);
    }
  }, []);

  return (
    <>
      {!isAdminPath && <Header />}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        <Route path="/register" element={<RegisterForm />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/main-form" element={<Form />} />
        <Route path="/bell-form" element={<BellForm />} />
        <Route path="/rogers-form" element={<RogerForm />} />
        <Route path="/rogers-mobility-form" element={<RogerMobility />} />
        <Route path="/admin" element={<Portal />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="orders" element={<Orders />} />
          <Route path="user" element={<User />} />
          <Route path="setTarget" element={<TargetForm />} />
          <Route path="setPrice" element={<PriceForm />} />
          <Route
            path="chat"
            element={<ChatForm userId={user ? user._id : null} />}
          />
          <Route path="documentation" element={<Documentation />} />
          <Route path="profit" element={<Profit />} />
          <Route path="logout" element={<Logout />} />
        </Route>
      </Routes>
    </>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
