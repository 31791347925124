import React from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const InvoiceGenerator = ({ data }) => {
  const generateInvoice = () => {
    const doc = new jsPDF();
    doc.setFontSize(18);
    doc.text('Invoice', 20, 20);
    doc.setFontSize(12);
    doc.text(`Client Name: ${data.clientName}`, 20, 30);
    doc.text(`Form Type: ${data.formType}`, 20, 40);
    doc.text(`Service Type: ${data.serviceType ? data.serviceType : 'N/A'}`, 20, 50);

    let servicePackageInfo = '';
    if (data.serviceType) {
      if (data.serviceType.homePhone && data.serviceType.homePhone.selected) {
        servicePackageInfo += `Home Phone Package: ${data.serviceType.homePhone.package}\n`;
      }
      if (data.serviceType.internet && data.serviceType.internet.selected) {
        servicePackageInfo += `Internet Package: ${data.serviceType.internet.package}\n`;
      }
      if (data.serviceType.tv && data.serviceType.tv.selected) {
        servicePackageInfo += `TV Package: ${data.serviceType.tv.package}\n`;
      }
    }
    doc.text(servicePackageInfo, 20, 60);
    doc.autoTable({
      startY: 80,
      head: [['Service', 'Price']],
      body: [
        ['Home Phone', data.clientHomePrice || 0],
        ['Internet', data.clientInternetPrice || 0],
        ['TV', data.clientTvPrice || 0],
      ],
    });
    const total = (data.clientHomePrice || 0) + (data.clientInternetPrice || 0) + (data.clientTvPrice || 0);
    doc.text(`Total: $${total}`, 20, doc.lastAutoTable.finalY + 10);
    doc.save(`${data.clientName}_invoice.pdf`);
  };

  return (
    <button onClick={generateInvoice}>Generate Invoice</button>
  );
};

export default InvoiceGenerator;
