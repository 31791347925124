import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { toast } from 'react-toastify';

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const ModalTitle = styled.h3`
  margin-bottom: 10px;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin-right: 10px;
`;

const ConnectionModal = ({ user, isOpen, onClose }) => {
  const [clients, setClients] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedAdmin, setSelectedAdmin] = useState("");
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/api/register/users');
        const allUsers = response.data;
        setClients(allUsers.filter((u) => u.role === 'client'));
        setAdmins(allUsers.filter((u) => u.role === 'admin'));
      } catch (error) {
        toast.error('Failed to fetch users.');
      }
    };

    if (isOpen) {
      fetchUsers();
    }
  }, [isOpen]);



  const handleConnection = async () => {
    if (!selectedClient || !selectedAdmin) {
      toast.error('Please select both a client and an admin');
      return;
    }

    try {
      await axios.post('/api/connections', {
        userId: user._id,
        clientId: selectedClient,
        adminId: selectedAdmin,
      });
      toast.success('Connection created successfully');
      onClose();
    } catch (error) {
      toast.error('Failed to create connection');
    }
  };


  return (
    isOpen && (
      <ModalContainer>
        <ModalTitle>Create Connection for {user.username}</ModalTitle>
        <Select value={selectedClient} onChange={(e) => setSelectedClient(e.target.value)}>
          <option value="">Select Client</option>
          {clients.map((client) => (
            <option key={client._id} value={client._id}>
              {client.username}
            </option>
          ))}
        </Select>
        <Select value={selectedAdmin} onChange={(e) => setSelectedAdmin(e.target.value)}>
          <option value="">Select Admin</option>
          {admins.map((admin) => (
            <option key={admin._id} value={admin._id}>
              {admin.username}
            </option>
          ))}
        </Select>
        <Button onClick={handleConnection}>Create Connection</Button>
        <Button onClick={onClose}>Cancel</Button>
      </ModalContainer>
    )
  );
};
export default ConnectionModal;
