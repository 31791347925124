import React from "react";
import styled from "styled-components";
import logo from "../../assets/LOGO.png";
const TopBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: black;
  color: black;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const Logo = styled.h1`
  margin: 0;
  font-size: 24px;
`;

const UserProfile = styled.div`
  cursor: pointer;
  color: white;
`;

const TopBar = () => {
  return (
    <TopBarContainer>
      <Logo>
        <img src={logo} alt="logo" height={70} width={250} />
      </Logo>
      <UserProfile>
        <a
          href="https://docs.google.com/spreadsheets/d/16DDJLhn7SPg7AOwCT3eR9uCnrH9ZVfKr7yCKmc_6v1I/edit?gid=365780203#gid=365780203"
          target="_blank"
          rel="noopener noreferrer"
        >
          Promotional
        </a>
      </UserProfile>
    </TopBarContainer>
  );
};

export default TopBar;
