import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import MultiDatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/yellow.css";
import { FaFilter } from "react-icons/fa";
import SalesTargetChart from "../Charts/SalesTargetChart";
import OrderRatiosChart from "../Charts/OrderRatiosChart";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import OrderTypeRatio from "../Charts/OrderTypeRatio";

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [targets, setTargets] = useState({
    bellTarget: 0,
    rogersTarget: 0,
    rogersMobilityTarget: 0,
  });
  const [data, setData] = useState({
    orders: {
      total: 0,
      received: 0,
      submitted: 0,
      installed: 0,
      booked: 0,
      cancelled: 0,
      issued: 0,
    },
  });
  const [selectedDates, setSelectedDates] = useState([]);
  const [filters, setFilters] = useState({
    centerName: "",
    clientName: "",
    status: "",
  });
  const [users, setUsers] = useState({ centers: [], clients: [] });
  const [showFilterModal, setShowFilterModal] = useState(false);
  const submitName = localStorage.getItem("username");

  useEffect(() => {
    fetchData();
    fetchUsers();
  }, []);

  const fetchData = async () => {
    try {
      const token =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      console.log("login token", token);
      if (!token) {
        throw new Error("Authentication token not found.");
      }

      const ordersResponse = await fetch("/api/orders", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!ordersResponse.ok) {
        throw new Error("Failed to fetch orders");
      }

      const ordersData = await ordersResponse.json();
      console.log("orders", ordersData);
      setData(ordersData);

      const targetsResponse = await fetch("/api/targets", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!targetsResponse.ok) {
        throw new Error("Failed to fetch targets");
      }

      const targetsData = await targetsResponse.json();
      console.log("targets", targetsData);

      const totalBellTarget = calculateTarget(targetsData, "bellTarget");
      const totalRogersTarget = calculateTarget(targetsData, "rogersTarget");
      const totalRogersMobilityTarget = calculateTarget(
        targetsData,
        "rogersMobilityTarget"
      );

      console.log(
        "targets for ind",
        totalBellTarget,
        totalRogersTarget,
        totalRogersMobilityTarget
      );

      setTargets({
        bellTarget: totalBellTarget,
        rogersTarget: totalRogersTarget,
        rogersMobilityTarget: totalRogersMobilityTarget,
      });

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const token =
    localStorage.getItem("token") || sessionStorage.getItem("token");
  const fetchUsers = async () => {
    try {
      const usersResponse = await axios.get("/api/register/users", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const usersData = usersResponse.data || [];
      const centers = usersData
        .filter((user) => user.role === "user")
        .map((user) => user.username);
      const clients = usersData
        .filter((user) => user.role === "client")
        .map((user) => user.username);

      setUsers({ centers, clients });
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
    }
  };
  const calculateTarget = (targetData, key) =>
    Array.isArray(targetData)
      ? targetData.reduce((sum, item) => sum + (item[key] || 0), 0)
      : 0;

  const calculatePercentage = (value, total) =>
    total > 0 ? ((value / total) * 100).toFixed(2) : 0;

  const handleApplyFilters = async () => {
    const queryParams = new URLSearchParams();

    if (filters.centerName?.length) {
      queryParams.append("centerName", filters.centerName.join(","));
    }
    if (filters.clientName?.length) {
      queryParams.append("clientName", filters.clientName.join(","));
    }
    if (selectedDates.length === 2) {
      queryParams.append("startDate", selectedDates[0]);
      queryParams.append("endDate", selectedDates[1]);
    }

    try {
      const response = await fetch(`/api/orders?${queryParams.toString()}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const ordersData = await response.json();
      setData(
        ordersData || {
          orders: {
            bell: [],
            rogers: [],
            rogersMobility: [],
          },
        }
      );
      console.log('ordersData', ordersData)
      setShowFilterModal(false);
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    }
  };

  const handleCheckboxChange = (e, filterType) => {
    const { id, checked } = e.target;
    setFilters((prev) => {
      const updated = { ...prev };
      if (checked) {
        updated[filterType] = [...(updated[filterType] || []), id];
      } else {
        updated[filterType] = (updated[filterType] || []).filter(
          (item) => item !== id
        );
      }
      return updated;
    });
  };

  const { orders } = data;
  const { total, received, submitted, installed, booked, cancelled, issued } =
    orders;

  const { bellTarget, rogersTarget, rogersMobilityTarget } = targets;
  const totalTarget = bellTarget + rogersTarget + rogersMobilityTarget;

  const completedOrders = installed;
  const toDoOrders = totalTarget - completedOrders;

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center text-white align-items-center"
        style={{ height: "100vh" }}
      >
        <h2>Loading...</h2>
      </div>
    );
  }
  return (
    <div className="container">
      <div className="row mb-4">
        <div className="col-md-2 d-flex align-items-center">
          <FaFilter
            size={24}
            className="btn btn-primary text-white btn-sm w-100"
            onClick={() => setShowFilterModal(true)}
          />
        </div>
      </div>
      <div className="row">
        {[
          { label: "Welcome", value: submitName, color: "info" },
          { label: "Total Orders", value: total, color: "primary" },
          { label: "Received Orders", value: received, color: "secondary" },
          { label: "Submitted Orders", value: submitted, color: "muted" },
        ].map((item, index) => (
          <OrderCard key={index} {...item} />
        ))}
      </div>

      <div className="row mt-3">
        {[
          { label: "Booked Orders", value: booked, color: "light" },
          { label: "Installed Orders", value: installed, color: "success" },
          { label: "Cancelled Orders", value: cancelled, color: "danger" },
          { label: "Issued Orders", value: issued, color: "warning" },
        ].map((item, index) => (
          <OrderCard key={index} {...item} />
        ))}
      </div>
      <div className="container">
        <div className="row mt-4">
          <div className="col-md-6 col-lg-4">
            <OrderTypeRatio
              data={{
                ordersDetails: {
                  bellOrders: data.ordersDetails?.bellOrders || [],
                  rogersOrders: data.ordersDetails?.rogersOrders || [],
                  rogersMobilityOrders:
                    data.ordersDetails?.rogersMobilityOrders || [],
                },
              }}
            />
          </div>
          <div className="col-md-6 col-lg-4">
            <SalesTargetChart
              bellTarget={bellTarget}
              rogersTarget={rogersTarget}
              rogersMobilityTarget={rogersMobilityTarget}
              completedOrders={completedOrders}
              toDoOrders={toDoOrders}
            />
          </div>
          <div className="col-md-6 col-lg-4">
            <OrderRatiosChart
              received={calculatePercentage(received, totalTarget)}
              submitted={calculatePercentage(submitted, totalTarget)}
              booked={calculatePercentage(booked, totalTarget)}
              completed={calculatePercentage(installed, totalTarget)}
              cancelled={calculatePercentage(cancelled, totalTarget)}
              issued={calculatePercentage(issued, totalTarget)}
            />
          </div>
        </div>
      </div>
      <Modal show={showFilterModal} onHide={() => setShowFilterModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Apply Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label>Select Dates</label>
            <MultiDatePicker
              multiple
              value={selectedDates}
              onChange={setSelectedDates}
              className="form-control form-control-sm"
              placeholder="Select dates"
            />
          </div>

          <div className="mb-3">
            <label>Center Name</label>
            <div>
              {users.centers.map((center) => (
                <div key={center} className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={center}
                    onChange={(e) => handleCheckboxChange(e, "centerName")}
                  />
                  <label className="form-check-label" htmlFor={center}>
                    {center}
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div className="mb-3">
            <label>Client Name</label>
            <div>
              {users.clients.map((client) => (
                <div key={client} className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={client}
                    onChange={(e) => handleCheckboxChange(e, "clientName")}
                  />
                  <label className="form-check-label" htmlFor={client}>
                    {client}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowFilterModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleApplyFilters}>
            Apply Filters
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
const OrderCard = ({ label, value, color }) => (
  <div className="col-md-3">
    <div className={`card p-3 text-center bg-${color}`}>
      <h6>{label}</h6>
      <h5>{value}</h5>
    </div>
  </div>
);

export default Dashboard;
