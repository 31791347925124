import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import styled from "styled-components";
import {Link, useNavigate } from "react-router-dom";
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CircleLoader } from "react-spinners";
import loginImage from "../../../assets/login.webp"; 

const Container = styled.div`
  background-color: red;
`;

const LeftSection = styled.div`
  background-color: white;
  padding: 40px;
`;

const RightSection = styled.div`
  position: relative;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const ImageOverlay = styled.div`
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  color: white;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7);
  z-index: 1;
`;

const StyledButton = styled.button`
  background-color: #1e2a4a;
  font-weight: bold;
`;

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (formData.email && formData.password) {
      setLoading(true);
      try {
        const response = await fetch("/api/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: formData.email,
            password: formData.password,
          }),
        });
  
        const data = await response.json();
  
        if (response.ok) {
          const { userId, token, username, role } = data;
          console.log("Login data", data);
  
          // Conditional token storage
          const keepLoggedIn = document.getElementById("keepMeLoggedIn").checked;
          if (keepLoggedIn) {
            localStorage.setItem("token", token);
            sessionStorage.removeItem("token");
          } else {
            sessionStorage.setItem("token", token);
            localStorage.removeItem("token");
          }
  
          // Store other user info
          localStorage.setItem("userId", userId);
          localStorage.setItem("username", username);
          localStorage.setItem("role", role);
  
          toast.success("Login successful!");
          navigate("/admin/dashboard");
        } else {
          toast.error(data.message || "Login failed");
        }
      } catch (error) {
        console.error("Login error:", error);
        toast.error("An error occurred, please try again.");
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("Please fill in all fields", {
        position: "top-center",
        autoClose: 2000,
      });
    }
  };
  
  return (
    <Container className="container-fluid vh-100 d-flex align-items-center justify-content-center"
    style={{ backgroundColor: "#F0F4FF" }}>
      <div className="row w-75 shadow-lg rounded-4 overflow-hidden">
        <LeftSection className="col-md-6 d-flex flex-column justify-content-center">
          <h3>Welcome Back</h3>
          <form onSubmit={handleLogin}>
            <div className="mb-3">
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <input
                type="password"
                name="password"
                className="form-control"
                placeholder="Your Password"
                value={formData.password}
                onChange={handleChange}
              />
            </div>
            <div className="form-check mb-3">
              <input type="checkbox" className="form-check-input" id="keepMeLoggedIn" />
              <label className="form-check-label" htmlFor="keepMeLoggedIn">
                Keep me logged in
              </label>
              <a href="#" className="float-end">Forgot password?</a>
            </div>

            {loading ? (
              <div className="text-center my-3">
                <CircleLoader size={100} color="#1e2a4a" />
              </div>
            ) : (
              <StyledButton type="submit" className="btn btn-primary w-100">
                Log in
              </StyledButton>
            )}
          </form>
          <p className="mt-3 text-center">
            Don’t have an account yet? <Link to="/register">Sign up</Link>
          </p>
        </LeftSection>

        <RightSection className="col-md-6">
          <ImageOverlay>
            <h4>New Update Available</h4>
          </ImageOverlay>
          <img
            src={loginImage}
            alt="Illustration"
            className="img-fluid"
            style={{ maxWidth: "100%", borderRadius: "10px", position: "relative", zIndex: 0 }}
          />
        </RightSection>
      </div>
    </Container>
  );
};

export default LoginForm;
