import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { IoReturnUpForwardOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import axios from 'axios'
const ForwardForm = ({ order }) => {
  console.log("Order object:", order);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [formData, setFormData] = useState({
    installationDate: "",
    timeSlot: "",
    clientName: order.clientName || "",
    status: order.status || "",
    payment: order.payment || "",
    comments: "",
  });
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const usersResponse = await axios.get("/api/register/users");
        const users = usersResponse.data;
        const clients = users
          .filter((user) => user.role === "client")
          .map((user) => user.username);
        setClientList(clients);
      } catch (error) {
        console.error("Error fetching client list:", error);
      }
    };

    fetchClients();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const orderId = order._id;
    const formType = order.formType;
    const senderName = localStorage.getItem("username");
    const timestamp = new Date();

    const newComment = {
      senderName: senderName,
      message: formData.comments,
      timestamp: timestamp,
    };
    const payload = {
      installationDate: formData.installationDate,
      timeSlot: formData.timeSlot,
      clientName: formData.clientName || order.clientName,
      status: formData.status,
      payment: formData.payment,
      comments: [newComment],
      senderName: senderName,
      timestamp: timestamp,
    };

    console.log("Payload data:", payload);

    try {
      let response;
      const token = localStorage.getItem("token") || sessionStorage.getItem("token");
      console.log("Auth Token:", token);
      response = await fetch(`/api/orders/${orderId}/${formType}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(payload),
      });
      console.log("Full API Response:", response);
      console.log("Payload data:", payload);
      if (response.ok || response.status === 200) {
        console.log("API Response:", response);
        toast.success("Form submitted successfully!");
        setFormData({
          installationDate: "",
          timeSlot: "",
          clientName: "",
          payment: "",
          status: "",
          comments: "",
        });
        handleClose();
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to update order");
      }
    } catch (error) {
      console.error("Submission error:", error.message);
      toast.error(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <IoReturnUpForwardOutline
        style={{ cursor: "pointer" }}
        onClick={handleShow}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Order Form:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="installationDate">
              <Form.Label>Installation Date</Form.Label>
              <Form.Control
                type="date"
                value={formData.installationDate}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="timeSlot">
              <Form.Label>Time Slot</Form.Label>
              <Form.Control
                as="select"
                value={formData.timeSlot}
                onChange={handleChange}
              >
                <option>8-10</option>
                <option>10-12</option>
                <option>12-2</option>
                <option>2-4</option>
                <option>4-6</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="clientName">
              <Form.Label>Client Name</Form.Label>
              <Form.Control
                as="select"
                value={formData.clientName}
                onChange={handleChange}
              >
                <option value="">Select a client</option>
                {clientList.map((client, index) => (
                  <option key={index} value={client}>
                    {client}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="status">
              <Form.Label>Installation Status</Form.Label>
              <Form.Control
                as="select"
                value={formData.status}
                onChange={handleChange}
              >
                <option value="submitted">Submitted</option>
                <option value="booked">Booked</option>
                <option value="installed">Installed</option>
                <option value="cancelled">Cancelled</option>
                <option value="issued">Issued</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="payment">
              <Form.Label>payment Status</Form.Label>
              <Form.Control
                as="select"
                value={formData.payment}
                onChange={handleChange}
              >
                <option value="">change Status</option>
                <option value="Paid">Paid</option>
                <option value="Unpaid">Unpaid</option>
                <option value="NR">NR</option>
                <option value="Charge Back">Charge Back</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="comments">
              <Form.Label>Comments</Form.Label>
              <Form.Control
                type="text"
                value={formData.comments}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ForwardForm;
