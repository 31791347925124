import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";

const TargetForm = () => {
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [formData, setFormData] = useState({
    username: "",
    clientname: "",
    bellTarget: "",
    rogersTarget: "",
    distributalTarget: "",
    telusTarget: "",
    cogecoTareget: "",
    shawTarget: "",
    bellMobilityTarget: "",
    rogersMobilityTarget:"",
    teluMobilityTarget:"",
    koodoMobilityTarget:"",
    chatrMobilityTarget:"",
    freedomMobilityTarget:"",
    virginMobilityTarget:"",
  });
  const [role, setRole] = useState("center");

  useEffect(() => {
    const fetchUsersAndClients = async () => {
      try {
        const response = await axios.get("/api/register/users");
        const allUsers = response.data;
        setUsers(allUsers.filter((user) => user.role === "user"));
        setClients(allUsers.filter((user) => user.role === "client"));
      } catch (error) {
        toast.error("Failed to fetch users and clients");
        console.error(error);
      }
    };
    fetchUsersAndClients();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (role === "center" && !formData.username) {
      toast.error("Please select a center.");
      return;
    }
    if (role === "client" && !formData.clientname) {
      toast.error("Please select a client.");
      return;
    }

    try {
      await axios.post("/api/targets", formData);
      toast.success("Target data saved successfully");
      setFormData({
        username: "",
        clientname: "",
        bellTarget: "",
        rogersTarget: "",
        distributalTarget: "",
        telusTarget: "",
        cogecoTareget: "",
        shawTarget: "",
        bellMobilityTarget: "",
        rogersMobilityTarget:"",
        teluMobilityTarget:"",
        koodoMobilityTarget:"",
        chatrMobilityTarget:"",
        freedomMobilityTarget:"",
        virginMobilityTarget:"",
      });
    } catch (error) {
      toast.error("Failed to save target data");
      console.error(error);
    }
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
    setFormData({
      ...formData,
      username: "",
      clientname: "",
    });
  };

  return (
    <div className="container mt-4">
      <h2 className="text-center mb-4 text-white">Assign Targets and Prices</h2>
      <form
        className="p-4 border rounded bg-light shadow-sm"
        onSubmit={handleSubmit}
      >
        <div className="mb-3">
          <label className="form-label">Set Target For</label>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              id="center"
              name="role"
              value="center"
              checked={role === "center"}
              onChange={handleRoleChange}
            />
            <label className="form-check-label" htmlFor="center">
              Center
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              id="client"
              name="role"
              value="client"
              checked={role === "client"}
              onChange={handleRoleChange}
            />
            <label className="form-check-label" htmlFor="client">
              Client
            </label>
          </div>
        </div>
        <div className="row">
          {role === "center" && (
            <div className="col-md-6 mb-3">
              <label htmlFor="username" className="form-label">
                Select Center
              </label>
              <select
                className="form-select"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                required
                min={0}
              >
                <option value="">Select a user</option>
                {users.map((user) => (
                  <option key={user._id.$oid} value={user.username}>
                    {user.username}
                  </option>
                ))}
              </select>
            </div>
          )}
          {role === "client" && (
            <div className="col-md-6 mb-3">
              <label htmlFor="clientname" className="form-label">
                Select Client
              </label>
              <select
                className="form-select"
                id="clientname"
                name="clientname"
                value={formData.clientname}
                onChange={handleChange}
                required
              >
                <option value="">Select a Client</option>
                {clients.map((client) => (
                  <option key={client._id.$oid} value={client.username}>
                    {client.username}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="col-md-6 mb-3">
            <label htmlFor="virginMobilityTarget" className="form-label">
            Virgin  Mobility Target
            </label>
            <input
              type="number"
              className="form-control"
              id="virginMobilityTarget"
              name="virginMobilityTarget"
              value={formData.virginMobilityTarget}
              onChange={handleChange}
              required
              min={0}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-3 mb-3">
            <label htmlFor="bellTarget" className="form-label">
              Bell Target
            </label>
            <input
              type="number"
              className="form-control"
              id="bellTarget"
              name="bellTarget"
              value={formData.bellTarget}
              onChange={handleChange}
              required
              min={0}
            />
          </div>
          <div className="col-md-3 mb-3">
            <label htmlFor="rogersTarget" className="form-label">
              Rogers Target
            </label>
            <input
              type="number"
              className="form-control"
              id="rogersTarget"
              name="rogersTarget"
              value={formData.rogersTarget}
              onChange={handleChange}
              required
              min={0}
            />
          </div>
          <div className="col-md-3 mb-3">
            <label htmlFor="distributalTarget" className="form-label">
              Distributal Target
            </label>
            <input
              type="number"
              className="form-control"
              id="distributalTarget"
              name="distributalTarget"
              value={formData.distributalTarget}
              onChange={handleChange}
              required
              min={0}
            />
          </div>
          <div className="col-md-3 mb-3">
            <label htmlFor="telusTarget" className="form-label">
              Telus Target
            </label>
            <input
              type="number"
              className="form-control"
              id="telusTarget"
              name="telusTarget"
              value={formData.telusTarget}
              onChange={handleChange}
              required
              min={0}
            />
          </div>

          <div className="row">
            <div className="col-md-3 mb-3">
              <label htmlFor="cogecoTareget" className="form-label">
                Cogeco Target
              </label>
              <input
                type="number"
                className="form-control"
                id="cogecoTareget"
                name="cogecoTareget"
                value={formData.cogecoTareget}
                onChange={handleChange}
                required
                min={0}
              />
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="shawTarget" className="form-label">
                Shaw Target
              </label>
              <input
                type="number"
                className="form-control"
                id="shawTarget"
                name="shawTarget"
                value={formData.shawTarget}
                onChange={handleChange}
                required
                min={0}
              />
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="bellMobilityTarget" className="form-label">
                Bell Mobility Target
              </label>
              <input
                type="number"
                className="form-control"
                id="bellMobilityTarget"
                name="bellMobilityTarget"
                value={formData.bellMobilityTarget}
                onChange={handleChange}
                required
                min={0}
              />
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="rogersMobilityTarget" className="form-label">
                Rogers Mobility Target
              </label>
              <input
                type="number"
                className="form-control"
                id="rogersMobilityTarget"
                name="rogersMobilityTarget"
                value={formData.rogersMobilityTarget}
                onChange={handleChange}
                required
                min={0}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 mb-3">
              <label htmlFor="teluMobilityTarget" className="form-label">
                Telus Mobility Target
              </label>
              <input
                type="number"
                className="form-control"
                id="teluMobilityTarget"
                name="teluMobilityTarget"
                value={formData.teluMobilityTarget}
                onChange={handleChange}
                required
                min={0}
              />
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="koodoMobilityTarget" className="form-label">
                koodo Mobility Target
              </label>
              <input
                type="number"
                className="form-control"
                id="koodoMobilityTarget"
                name="koodoMobilityTarget"
                value={formData.koodoMobilityTarget}
                onChange={handleChange}
                required
                min={0}
              />
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="chatrMobilityTarget" className="form-label">
                Chatr Mobility Target
              </label>
              <input
                type="number"
                className="form-control"
                id="chatrMobilityTarget"
                name="chatrMobilityTarget"
                value={formData.chatrMobilityTarget}
                onChange={handleChange}
                required
                min={0}
              />
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="freedomMobilityTarget" className="form-label">
                Freedom Mobility Target
              </label>
              <input 
                type="number"
                className="form-control"
                id="freedomMobilityTarget"
                name="freedomMobilityTarget"
                value={formData.freedomMobilityTarget}
                onChange={handleChange}
                required
                min={0}
              />
            </div>
          </div>
        </div>
        <div className="text-center">
          <button type="submit" className="btn btn-primary px-5">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default TargetForm;
