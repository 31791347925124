import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { Popover, OverlayTrigger } from "react-bootstrap";
import BellModal from "../../copiedForm/BellForm";
import { FaFilter, FaEdit, FaTrash } from "react-icons/fa";
import ForwardForm from "../../Form/ForwardIconForm/ForwardForm";
const Orders = () => {
  const [statusFilter, setStatusFilter] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [centers, setCenters] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedCenters, setSelectedCenters] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [orders, setOrders] = useState({
    ordersDetails: {
      bellOrders: [],
      rogersOrders: [],
      rogersMobilityOrders: [],
    },
    orders: { total: 0, received: 0, installed: 0, booked: 0, cancelled: 0 },
  });
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token =
          localStorage.getItem("token") || sessionStorage.getItem("token");
        console.log("login token", token);
        if (!token) {
          throw new Error("Authentication token not found.");
        }

        const ordersResponse = await fetch("/api/orders", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!ordersResponse.ok) {
          throw new Error("Failed to fetch orders");
        }

        const ordersData = await ordersResponse.json();
        console.log("orders", ordersData);
        setOrders(ordersData);
        const usersResponse = await axios.get("/api/register/users", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("users", usersResponse.data);
        const users = usersResponse.data;
        const centerList = users
          .filter((user) => user.role === "user")
          .map((user) => user.username);
        const clientList = users
          .filter((user) => user.role === "client")
          .map((user) => user.username);

        setCenters(centerList);
        setClients(clientList);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, []);

  const allOrders = [
    ...orders.ordersDetails.bellOrders,
    ...orders.ordersDetails.rogersOrders,
    ...orders.ordersDetails.rogersMobilityOrders,
  ];

  console.log("All orders", allOrders);
  const toggleFilterModal = () => setShowFilterModal(!showFilterModal);

  const handleStatusFilter = (status) => {
    setStatusFilter(status);
  };

  const handleCheckboxChange = (name, list, setList) => {
    setList((prevList) =>
      prevList.includes(name)
        ? prevList.filter((item) => item !== name)
        : [...prevList, name]
    );
  };

  const handleCloseModal = () => setShowModal(false);

  const filteredOrders = allOrders.filter((order) => {
    const matchesSearchTerm =
      (order.customerName &&
        order.customerName.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (order.formType &&
        order.formType.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (order._id && order._id.includes(searchTerm)) ||
      (order.status &&
        order.status.toLowerCase().includes(searchTerm.toLowerCase()));

    const matchesStatusFilter =
      statusFilter === "all" || order.status === statusFilter;
    const matchesCenterFilter =
      selectedCenters.length === 0 ||
      selectedCenters.includes(order.centerName);
    const matchesClientFilter =
      selectedClients.length === 0 ||
      selectedClients.includes(order.clientName);
    const matchesPaymentStatus =
      paymentStatus.length === 0 || paymentStatus.includes(order.payment);

    return (
      matchesSearchTerm &&
      matchesStatusFilter &&
      matchesCenterFilter &&
      matchesClientFilter &&
      matchesPaymentStatus
    );
  });

  const orderCounts = {
    total: orders.orders?.total || 0,
    received: orders.orders?.received || 0,
    installed: orders.orders?.installed || 0,
    booked: orders.orders?.booked || 0,
    cancelled: orders.orders?.cancelled || 0,
  };

  console.log("orders counts", orderCounts);

  const totalItems = filteredOrders.length;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems);
  const paginatedOrders = filteredOrders.slice(startIndex, endIndex);
  console.log("paginatedOrders", paginatedOrders);

  const handleNextPage = () => {
    if (currentPage < Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const deleteOrder = async (id, formType) => {
    try {
      await axios.delete(`/api/orders/${formType}/${id}`);
      setOrders((prevOrders) => ({
        ...prevOrders,
        ordersDetails: {
          ...prevOrders.ordersDetails,
          bellOrders: prevOrders.ordersDetails.bellOrders.filter(
            (order) => order._id !== id
          ),
        },
      }));
    } catch (error) {
      console.error("Error deleting order", error);
    }
  };
  const styles = {
    ordersContainer: {
      backgroundColor: "#1e2a38",
      position: "relative",
      padding: "20px",
      borderRadius: "10px",
      color: "white",
      fontSize: "x-small",
      fontWeight: 500,
    },
    tableThead: {
      backgroundColor: "#293848",
    },
    tableCell: {
      color: "white",
      fontSize: "x-small",
      fontWeight: 500,
    },
    btnOutlinePrimary: {
      borderColor: "#00b894",
      color: "#00b894",
      fontSize: "x-small",
      fontWeight: 500,
    },
    btnPrimary: {
      backgroundColor: "#00b894",
      borderColor: "#00b894",
      borderRadius: "10px",
    },
    btnPrimaryHover: {
      backgroundColor: "#009374",
      borderColor: "#009374",
    },
    badgeSuccess: {
      backgroundColor: "#00b894",
    },
    badgeWarning: {
      backgroundColor: "#f39c12",
    },
    badgeDanger: {
      backgroundColor: "#e74c3c",
    },
    badgeSecondary: {
      backgroundColor: "#6c757d",
    },
    ordersFooter: {
      textAlign: "right",
      color: "#bdc3c7",
    },
    paymentClasses: {
      Paid: "bg-success",
      NR: "bg-primary",
      "Charge Back": "bg-danger",
      Unpaid: "bg-warning",
    },
    orderStatus: {
      received: "bg-secondary",
      submitted: "bg-info",
      booked: "bg-primary",
      installed: "bg-success",
      cancelled: "bg-danger",
      issued: "bg-warning",
    },
  };

  return (
    <div style={styles.ordersContainer}>
      <div className="row mb-3">
        <div className="col-md-8">
          <div className="btn-group">
            <button
              className={`btn ${
                statusFilter === "all" ? "btn-primary" : "btn-outline-primary"
              }`}
              onClick={() => handleStatusFilter("all")}
            >
              All Orders{" "}
              <span className="badge bg-secondary">{orderCounts.total}</span>
            </button>
            <button
              className={`btn ${
                statusFilter === "booked"
                  ? "btn-primary"
                  : "btn-outline-primary"
              }`}
              onClick={() => handleStatusFilter("booked")}
            >
              Booked{" "}
              <span className="badge bg-secondary">{orderCounts.booked}</span>
            </button>
            <button
              className={`btn ${
                statusFilter === "installed"
                  ? "btn-primary"
                  : "btn-outline-primary"
              }`}
              onClick={() => handleStatusFilter("installed")}
            >
              Installed{" "}
              <span className="badge bg-secondary">
                {orderCounts.installed}
              </span>
            </button>
            <button
              className={`btn ${
                statusFilter === "cancelled"
                  ? "btn-primary"
                  : "btn-outline-primary"
              }`}
              onClick={() => handleStatusFilter("cancelled")}
            >
              Cancelled{" "}
              <span className="badge bg-secondary">
                {orderCounts.cancelled}
              </span>
            </button>
            <button
              className={`btn ${
                statusFilter === "issued"
                  ? "btn-primary"
                  : "btn-outline-primary"
              }`}
              onClick={() => handleStatusFilter("issued")}
            >
              Issued{" "}
              <span className="badge bg-secondary">{orderCounts.issued}</span>
            </button>
          </div>
        </div>
        <div className="col-md-4 d-flex">
          <input
            type="text"
            className="form-control me-2"
            placeholder="Search for ID, Type, customer, order status..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      <button onClick={toggleFilterModal} className="btn btn-outline-secondary">
        <FaFilter /> Filter
      </button>

      {showFilterModal && (
        <div
          className="modal show d-block"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Filter Orders</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={toggleFilterModal}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  {/* Center Checkboxes */}
                  <div className="col-4">
                    <h6 style={{ color: "black" }}>Centers</h6>
                    {centers.map((center, index) => (
                      <div key={index} className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`center-${index}`}
                          value={center}
                          checked={selectedCenters.includes(center)}
                          onChange={() =>
                            handleCheckboxChange(
                              center,
                              selectedCenters,
                              setSelectedCenters
                            )
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`center-${index}`}
                          style={{ color: "black" }}
                        >
                          {center}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="col-4">
                    <h6 style={{ color: "black" }}>Clients</h6>
                    {clients.map((client, index) => (
                      <div key={index} className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`client-${index}`}
                          value={client}
                          checked={selectedClients.includes(client)}
                          onChange={() =>
                            handleCheckboxChange(
                              client,
                              selectedClients,
                              setSelectedClients
                            )
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`client-${index}`}
                          style={{ color: "black" }}
                        >
                          {client}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="col-4">
                    <h6 style={{ color: "black" }}>Payment Status</h6>
                    {["Paid", "Unpaid", "NR", "Charge Back"].map(
                      (status, index) => (
                        <div key={index} className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`payment-${index}`}
                            value={status}
                            checked={paymentStatus.includes(status)}
                            onChange={() =>
                              handleCheckboxChange(
                                status,
                                paymentStatus,
                                setPaymentStatus
                              )
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`payment-${index}`}
                            style={{ color: "black" }}
                          >
                            {status}
                          </label>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={toggleFilterModal}
                >
                  Apply Filter
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="table-responsive">
        <table className="table table-dark table-hover">
          <thead style={styles.tableThead}>
            <tr>
              <th style={styles.tableCell}>ORDER ID</th>
              <th style={styles.tableCell}>Created At</th>
              <th style={styles.tableCell}>ORDER Type</th>
              <th style={styles.tableCell}>Center Name</th>
              <th style={styles.tableCell}>Client Name</th>
              <th style={styles.tableCell}>Cx Name</th>
              <th style={styles.tableCell}>Products</th>
              <th style={styles.tableCell}>Installation Date</th>
              <th style={styles.tableCell}>Order Status</th>
              <th style={styles.tableCell}>Payment Status</th>
              <th style={styles.tableCell}>Action</th>
            </tr>
          </thead>
          <tbody>
            {paginatedOrders.map((order) => (
              <OverlayTrigger
                key={order._id}
                trigger="hover"
                placement="auto"
                overlay={
                  <Popover
                    id={`popover-${order._id}`}
                    className="custom-popover"
                  >
                    <Popover.Header as="h3" style={{ zIndex: 1060 }}>
                      Comments
                    </Popover.Header>
                    <Popover.Body style={{ zIndex: 1060 }}>
                      {order.comments && order.comments.length > 0 ? (
                        order.comments.map((comment, index) => (
                          <div key={index} className="mb-2">
                            <strong>{comment.senderName}:</strong>{" "}
                            {comment.message}
                            <br />
                            <small className="text-muted">
                              {new Date(comment.timestamp).toLocaleString()}
                            </small>
                          </div>
                        ))
                      ) : (
                        <div className="text-muted">No comments</div>
                      )}
                    </Popover.Body>
                  </Popover>
                }
              >
                <tr>
                  <td style={styles.tableCell}>
                    {order._id ? order._id.slice(-5) : ""}
                  </td>
                  <td style={styles.tableCell}>
                    {order.createdAt
                      ? (() => {
                          const date = new Date(order.createdAt);
                          const day = String(date.getDate()).padStart(2, "0");
                          const month = date.toLocaleString("default", {
                            month: "short",
                          });
                          const year = date.getFullYear();
                          return `${day}-${month}-${year}`;
                        })()
                      : "20-Jun-2020"}
                  </td>
                  <td style={styles.tableCell}>{order.formType || "N/A"}</td>
                  <td style={styles.tableCell}>{order.centerName || "N/A"}</td>
                  <td style={styles.tableCell}>{order.clientName || "N/A"}</td>
                  <td style={styles.tableCell}>
                    {order.customerName || order.personalfullName || "CX Name"}
                  </td>
                  <td style={styles.tableCell}>
                    {(() => {
                      const services = Object.entries(order.serviceType || {});
                      const selectedCount = services.filter(
                        ([key, value]) => value.selected === true
                      ).length;

                      if (selectedCount === 1) {
                        return "Single";
                      } else if (selectedCount === 2) {
                        return "Double";
                      } else if (selectedCount === 3) {
                        return "Trio";
                      } else {
                        return "None";
                      }
                    })()}
                  </td>

                  <td style={styles.tableCell}>
                    {order.installationDate
                      ? (() => {
                          const date = new Date(order.installationDate);
                          const day = String(date.getDate()).padStart(2, "0");
                          const month = date.toLocaleString("default", {
                            month: "short",
                          });
                          const year = date.getFullYear();
                          return `${day}-${month}-${year}`;
                        })()
                      : "20-Jun-2020"}
                  </td>
                  <td style={styles.tableCell}>
                    <span
                      className={`badge ${
                        styles.orderStatus[order.status] || ""
                      }`}
                    >
                      {order.status || "received"}
                    </span>
                  </td>

                  <td style={styles.tableCell}>{order.payment || "Unpaid"}</td>

                  <td style={styles.tableCell}>
                    <FaTrash
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => deleteOrder(order._id, order.formType)}
                    />
                    <ForwardForm
                      order={order}
                      show={showModal}
                      handleClose={handleCloseModal}
                    />

                    <BellModal
                      order={order}
                      show={showModal}
                      handleClose={handleCloseModal}
                      style={{ zIndex: 1050 }}
                      backdropStyle={{ zIndex: 1049 }}
                    />
                  </td>
                </tr>
              </OverlayTrigger>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <button
          className="btn btn-secondary me-2"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <button
          className="btn btn-secondary"
          onClick={handleNextPage}
          disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Orders;
