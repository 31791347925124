import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../assets/LOGO.png'
const Footer = () => {
  return (
    <footer className="bg-dark text-light py-3">
      <div className="container d-flex justify-content-between">
        <div>
          <img 
            src={logo} 
            alt="Logo" 
            style={{ height: '50px' }} 
          />
        </div>
        
        <div className="text-center">
          <p className="mb-0">
            Developed by <a href="https://www.theselectecode.com" className="text-decoration-none text-primary" target="_blank" rel="noopener noreferrer">TheSelecteCode.com</a>
          </p>
          <p className="mb-0">© 2024 All Rights Reserved</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
