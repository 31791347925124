import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import logo from '../../assets/LOGO.png';

const Header = () => {
  return (
    <Navbar bg="dark" expand="sm">
      <Container >
        <Navbar.Brand href="/">
          <img
            src={logo}
            width="200"
            height="50"
            className="d-inline-block align-top "
            alt="Logo"
          />
        </Navbar.Brand>
        <p className='text-white'>
        <a
          href="https://docs.google.com/spreadsheets/d/16DDJLhn7SPg7AOwCT3eR9uCnrH9ZVfKr7yCKmc_6v1I/edit?gid=365780203#gid=365780203"
          target="_blank"
          rel="noopener noreferrer"
        >
          Promotional
        </a>
        </p>
      </Container>
    </Navbar>
  );
};

export default Header;
