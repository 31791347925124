import React from 'react';
import styled from 'styled-components';
import TopBar from '../layout/TopBar';
import SideBar from '../layout/SideBar';
import { Outlet } from 'react-router-dom'; 

const PortalContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: linear-gradient(to bottom, #102D3B, #193E4F);
`;
const MainContentContainer = styled.div`
  display: flex;
  flex: 1;
`;

const MainContent = styled.div`
  flex: 1;
  padding: 20px;
  margin-top: 1px;
  background: linear-gradient(to right, #102D3B, #193E4F);
  border-radius: 10px; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
`;

const Portal = () => {
  return (
    <PortalContainer>
      <TopBar />
      <MainContentContainer>
        <SideBar />
        <MainContent>
          <Outlet /> 
        </MainContent>
      </MainContentContainer>
    </PortalContainer>
  );
};

export default Portal;
