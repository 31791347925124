import React, { useEffect, useState } from "react";
import axios from "axios";
import InvoiceGenerator from './InvoiceGenerator';
const Profit = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const centerName = localStorage.getItem("username");
  const role = localStorage.getItem("role");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token") || sessionStorage.getItem("token");
        setLoading(true);
        const [ordersResponse, pricesResponse] = await Promise.all([
          axios.get("/api/orders", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          axios.get("/api/prices", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        ]);  
        const ordersDetails = ordersResponse.data.ordersDetails;
        const prices = pricesResponse.data;
  
        if (!Array.isArray(prices)) {
          throw new Error("Prices response is not in the expected format.");
        }
  
        const allOrders = [
          ...ordersDetails.bellOrders,
          ...ordersDetails.rogersOrders,
          ...ordersDetails.rogersMobilityOrders,
        ];
          const filteredOrders = allOrders.filter(
          (order) =>
            (role === "admin" || order.centerName === centerName) &&
            order.status === "installed"
        );
  
        const orderCounts = {
          bellSingle: 0,
          bellDouble: 0,
          bellTrio: 0,
          distributalSingle:0,
          distributalDouble:0,
          distributalTrio:0,
          telusSingle:0,
          telusDouble:0,
          telusTrio:0,
          cogecoSingle:0,
          cogecoDobule:0,
          cogecoTrio:0,
          shawSingle:0,
          shawDouble:0,
          shawTrio:0,
          rogersSingle: 0,
          rogersDouble: 0,
          rogersTrio: 0,
          rogerMobility:0,
          bellMobility:0,
          telusMobility:0,
          koodoMobility:0,
          chatrMobility:0,
          freedomMobility:0,
          virginMobility:0,  
        };
  
        let centerTotalEarnings = 0;
        let centerBellEarnings = 0;
        let centerRogersEarnings = 0;
        let centerMobilityEarnings = 0;
        let adminBellProfit = 0;
        let adminRogersProfit = 0;
        let adminMobilityProfit = 0;
  
        filteredOrders.forEach((order) => {
          const { serviceType, formType, clientName } = order;
          console.log('orders', order)
          const clientPrices = prices.find( 
            (price) =>
              price.centerName === order.centerName &&
              price.clientName === clientName
          );
  
          if (!clientPrices) {
            console.warn(`No matching prices found for client: ${clientName}`);
            return;
          }
          
          let countedBell = false;
          let countedRogers = false;
            if (formType === "Bell" || formType === "Rogers" || formType ==="Distributal" || formType ==="Telus" || formType ==="Cogeco" || formType ==="Shaw") {
            const hasHomePhone = serviceType?.homePhone?.selected;
            const hasInternet = serviceType?.internet?.selected;
            const hasTv = serviceType?.tv?.selected;
              if (hasHomePhone && hasInternet && hasTv) {
              // Trio
              if (formType === "Bell" && !countedBell) {
                orderCounts.bellTrio++;
                countedBell = true;
                const clientPrice = clientPrices.clientTvBellPrice + clientPrices.clientInternetBellPrice + clientPrices.clientHomeBellPrice;
                const centerPrice = clientPrices.centerTvBellPrice + clientPrices.centerInternetBellPrice + clientPrices.centerhomeBellPrice;
                centerBellEarnings += centerPrice;
                adminBellProfit += clientPrice - centerPrice;
              } 
              if (formType === "Rogers" && !countedRogers) {
                orderCounts.rogersTrio++;
                countedRogers = true;
                const clientPrice = clientPrices.clientTvRogersPrice + clientPrices.clientInternetRogersPrice + clientPrices.clientHomeRogersPrice;
                const centerPrice = clientPrices.centerTvRogersPrice + clientPrices.centerInternetRogersPrice + clientPrices.centerHomeRogersPrice;
                centerRogersEarnings += centerPrice;
                adminRogersProfit += clientPrice - centerPrice;
              }
              if (formType === "Distributal" && !countedBell) {
                orderCounts.distributalTrio++;
                countedBell = true;
                const clientPrice = clientPrices.distriButalHomeClient + clientPrices.distriButalInternetClient + clientPrices.distributalTVClient;
                const centerPrice = clientPrices.distributalTVCenter + clientPrices.distriButalInternetCenter + clientPrices.distriButalHomeCenter;
                centerBellEarnings += centerPrice;
                adminBellProfit += clientPrice - centerPrice;
              } 
              if (formType === "Telus" && !countedBell) {
                orderCounts.telusTrio++;
                countedBell = true;
                const clientPrice = clientPrices.telusTvClient + clientPrices.telusInternetClient + clientPrices.telusHomeClient;
                const centerPrice = clientPrices.telusTvCenter + clientPrices.telusInternetCenter + clientPrices.telusHomeCenter;
                centerBellEarnings += centerPrice;
                adminBellProfit += clientPrice - centerPrice;
              } 
              if (formType === "Cogeco" && !countedBell) {
                orderCounts.cogecoTrio++;
                countedBell = true;
                const clientPrice = clientPrices.cogectoTvClient + clientPrices.cogecoInternetClient + clientPrices.cogectoHomeClinet;
                const centerPrice = clientPrices.cogectoTvCenter + clientPrices.cogecoInternetCenter + clientPrices.cogectoHomeCenter;
                centerBellEarnings += centerPrice;
                adminBellProfit += clientPrice - centerPrice;
              } 
              if (formType === "Shaw" && !countedBell) {
                orderCounts.shawTrio++;
                countedBell = true;
                const clientPrice = clientPrices.shawTvclient + clientPrices.shawInterentClient + clientPrices.shawHomeClient;
                const centerPrice = clientPrices.shawTvCenter + clientPrices.shawInterentCenter + clientPrices.shawHomeCenter;
                centerBellEarnings += centerPrice;
                adminBellProfit += clientPrice - centerPrice;
              } 
            } else if (hasTv && hasInternet) {
              if (formType === "Bell" && !countedBell) {
                orderCounts.bellDouble++;
                countedBell = true;
                const clientPrice = clientPrices.clientInternetBellPrice + clientPrices.clientTvBellPrice;
                const centerPrice = clientPrices.centerInternetBellPrice + clientPrices.centerTvBellPrice;
                centerBellEarnings += centerPrice;
                adminBellProfit += clientPrice - centerPrice;
              }
              if (formType === "Distributal" && !countedBell) {
                orderCounts.distributalTrio++;
                countedBell = true;
                const clientPrice = clientPrices.distributalTVClient + clientPrices.distriButalInternetClient;
                const centerPrice = clientPrices.distributalTVCenter + clientPrices.distriButalInternetCenter;
                centerBellEarnings += centerPrice;
                adminBellProfit += clientPrice - centerPrice;
              } 
              if (formType === "Telus" && !countedBell) {
                orderCounts.telusTrio++;
                countedBell = true;
                const clientPrice = clientPrices.telusInternetClient + clientPrices.telusTvClient;
                const centerPrice = clientPrices.telusInternetCenter + clientPrices.telusTvCenter;
                centerBellEarnings += centerPrice;
                adminBellProfit += clientPrice - centerPrice;
              } 
              if (formType === "Cogeco" && !countedBell) {
                orderCounts.cogecoTrio++;
                countedBell = true;
                const clientPrice =clientPrices.cogecoInternetClient + clientPrices.cogectoTvClient;
                const centerPrice = clientPrices.cogecoInternetCenter + clientPrices.cogectoTvCenter;
                centerBellEarnings += centerPrice;
                adminBellProfit += clientPrice - centerPrice;
              } 
              if (formType === "Shaw" && !countedBell) {
                orderCounts.shawTrio++;
                countedBell = true;
                const clientPrice = clientPrices.shawInterentClient + clientPrices.shawTvclient;
                const centerPrice = clientPrices.shawInterentCenter + clientPrices.shawTvCenter;
                centerBellEarnings += centerPrice;
                adminBellProfit += clientPrice - centerPrice;
              } 
              if (formType === "Rogers" && !countedRogers) {
                orderCounts.rogersDouble++;
                countedRogers = true;
                const clientPrice = clientPrices.clientInternetRogersPrice + clientPrices.clientTvRogersPrice;
                const centerPrice = clientPrices.centerInternetRogersPrice + clientPrices.centerTvRogersPrice;
                centerRogersEarnings += centerPrice;
                adminRogersProfit += clientPrice - centerPrice;
              }
            } else if (hasInternet) {
              // Single
              if (formType === "Bell" && !countedBell) {
                orderCounts.bellSingle++;
                countedBell = true;
                const clientPrice = clientPrices.clientInternetBellPrice;
                const centerPrice = clientPrices.centerInternetBellPrice;
                centerBellEarnings += centerPrice;
                adminBellProfit += clientPrice - centerPrice;
              }
              if (formType === "Distributal" && !countedBell) {
                orderCounts.distributalTrio++;
                countedBell = true;
                const clientPrice = clientPrices.distriButalInternetClient;
                const centerPrice = clientPrices.distriButalInternetCenter;
                centerBellEarnings += centerPrice;
                adminBellProfit += clientPrice - centerPrice;
              } 
              if (formType === "Telus" && !countedBell) {
                orderCounts.telusTrio++;
                countedBell = true;
                const clientPrice =clientPrices.telusInternetClient;
                const centerPrice =clientPrices.telusInternetCenter;
                centerBellEarnings += centerPrice;
                adminBellProfit += clientPrice - centerPrice;
              } 
              if (formType === "Cogeco" && !countedBell) {
                orderCounts.cogecoTrio++;
                countedBell = true;
                const clientPrice = clientPrices.cogecoInternetClient;
                const centerPrice =clientPrices.cogecoInternetCenter;
                centerBellEarnings += centerPrice;
                adminBellProfit += clientPrice - centerPrice;
              } 
              if (formType === "Shaw" && !countedBell) {
                orderCounts.shawTrio++;
                countedBell = true;
                const clientPrice = clientPrices.shawInterentClient;
                const centerPrice = clientPrices.shawInterentCenter;
                centerBellEarnings += centerPrice;
                adminBellProfit += clientPrice - centerPrice;
              } 
              if (formType === "Rogers" && !countedRogers) {
                orderCounts.rogersSingle++;
                countedRogers = true;
                const clientPrice = clientPrices.clientInternetRogersPrice;
                const centerPrice = clientPrices.centerInternetRogersPrice;
                centerRogersEarnings += centerPrice;
                adminRogersProfit += clientPrice - centerPrice;
              }
            }
          }
          else if (formType === "Rogers Mobility_R" && !countedRogers) {
            const portSectionsCount = order.portSections.length;
            console.log('portSectionsCount', portSectionsCount)
            const clientPrice = clientPrices.rogersMobilityClient_R * portSectionsCount;
            const centerPrice = clientPrices.rogerMobilityCenter_R * portSectionsCount;
            centerMobilityEarnings += centerPrice;
            adminMobilityProfit += clientPrice - centerPrice;      
          }
        });
        
        centerTotalEarnings = centerBellEarnings + centerRogersEarnings +centerMobilityEarnings;
        console.log('Bel Earnings', centerBellEarnings)
        console.log('rgoers Earnigng', centerRogersEarnings);
        console.log('Mobility Earnigng', centerMobilityEarnings);
        console.log('total earnings', centerTotalEarnings);
        const adminTotalProfit = adminBellProfit + adminRogersProfit + adminMobilityProfit;
  
        console.log(
          "Admin profit",
          adminTotalProfit,
          adminBellProfit,
          adminRogersProfit, 
          adminMobilityProfit
        );
  
        setData({
          centerTotalEarnings,
          centerBellEarnings,
          centerRogersEarnings,
          centerMobilityEarnings,
          adminBellProfit,
          adminRogersProfit,
          adminMobilityProfit,
          adminTotalProfit,
          bellSingle: orderCounts.bellSingle,
          bellDouble: orderCounts.bellDouble,
          bellTrio: orderCounts.bellTrio,
          rogersSingle: orderCounts.rogersSingle,
          rogersDouble: orderCounts.rogersDouble,
          rogersTrio: orderCounts.rogersTrio,
        });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      } 
    };
  
    fetchData();
  }, [centerName, role]);
    

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mt-4">
      <h3 className="text-white">Welcome {centerName}</h3>
      <div className="row">
        {role === "user" && (
          <>
            <div className="col-md-3">
              <div className="card bg-primary text-white">
                <div className="card-body">
                  <h5 className="card-title">Total Earnings</h5>
                  <p className="card-text">
                    <strong>${data.centerTotalEarnings.toFixed(2)}</strong>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="card bg-success text-white">
                <div className="card-body">
                  <h5 className="card-title">Bell Earnings</h5>
                  <p className="card-text">
                    <strong>${data.centerBellEarnings.toFixed(2)}</strong>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="card bg-warning text-dark">
                <div className="card-body">
                  <h5 className="card-title">Rogers Earnings</h5>
                  <p className="card-text">
                    <strong>${data.centerRogersEarnings.toFixed(2)}</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card bg-warning text-dark">
                <div className="card-body">
                  <h5 className="card-title">Mobility Earnings</h5>
                  <p className="card-text">
                  <strong>${data.centerMobilityEarnings.toFixed(2)}</strong>
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
        {/* Admin Section */}
        {role === "admin" && (
          <>
            <div className="col-md-4 mt-3">
              <div className="card bg-primary text-white">
                <div className="card-body">
                  <h5 className="card-title">Bell Profit</h5>
                  <p className="card-text">
                    <strong>${data.adminBellProfit.toFixed(2)}</strong>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-3">
              <div className="card bg-success text-dark">
                <div className="card-body">
                  <h5 className="card-title">Rogers Profit</h5>
                  <p className="card-text">
                    <strong>${data.adminRogersProfit.toFixed(2)}</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="card bg-success text-dark">
                <div className="card-body">
                  <h5 className="card-title">Mobility Profit</h5>
                  <p className="card-text">
                    <strong>${data.adminMobilityProfit.toFixed(2)}</strong>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-3">
              <div className="card bg-warning text-white">
                <div className="card-body">
                  <h5 className="card-title">Total Profit</h5>
                  <p className="card-text">
                    <strong>${data.adminTotalProfit.toFixed(2)}</strong>
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="row mt-5">
        <table className="table table-bordered table-striped">
          <thead className="thead-dark">
            <tr>
              <th>Service Type</th>
              <th>Single Orders <small>(Interent)</small></th>
              <th>Double Orders <small>(Internet+TV)</small></th>
              <th>Trio Orders<small>(Home+Int+TV)</small></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Bell</td>
              <td>{data.bellSingle}</td>
              <td>{data.bellDouble}</td>
              <td>{data.bellTrio}</td>
            </tr>
            <tr>
              <td>Rogers</td>
              <td>{data.rogersSingle}</td>
              <td>{data.rogersDouble}</td>
              <td>{data.rogersTrio}</td>
            </tr>
            <tr>
              <td>Mobility</td>
              <td>{data.portSectionsCount}</td>
              <td>-</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Total Orders</td>
              <td>{data.bellSingle + data.rogersSingle}</td>
              <td>{data.bellDouble + data.rogersDouble}</td>
              <td>{data.bellTrio + data.rogersTrio}</td>
            </tr>
          </tbody>
        </table>
        <InvoiceGenerator data={data} />
      </div>
    </div>
  );
};

export default Profit;
