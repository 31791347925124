import React, { useState, useEffect, useRef } from "react";
import socket from "../socket/socket";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import styles from "./ChatForm.module.css";
const ChatForm = () => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [userId, setUserId] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [connections, setConnections] = useState([]);

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    if (storedUserId) {
      setUserId(storedUserId);
    } else {
      toast.error("User not logged in");
    }
  }, []);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);
  useEffect(() => {
    if (!userId) return;

    const fetchConnections = async () => {
      try {
        const response = await axios.get(`/api/connections/${userId}`);
        setConnections(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error("Error fetching connections:", error);
        toast.error("Failed to load connections");
      }
    };

    fetchConnections();
  }, [userId]);

  useEffect(() => {
    const notificationAudio = new Audio(
      "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
    );

    const playNotificationSound = () => {
      try {
        notificationAudio.currentTime = 0;
        notificationAudio.play().catch((error) => {
          console.error("Audio playback failed:", error);
        });
      } catch (error) {
        console.error("Failed to play sound:", error);
      }
    };

    if (!userId) return;

    socket.emit("join", userId);

    socket.on("connect", () => {
      console.log("Socket connected to server");
    });

    socket.on("newMessage", (newMessage) => {
      const isRelevant =
        newMessage.senderId === selectedChat?.clientId ||
        newMessage.senderId === selectedChat?.adminId ||
        newMessage.recipientId === selectedChat?.clientId ||
        newMessage.recipientId === selectedChat?.adminId;

      if (isRelevant) {
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        toast.success("New message received");
        playNotificationSound();
      }
    });

    return () => {
      socket.off("newMessage");
      socket.emit("leave", userId);
    };
  }, [userId, selectedChat]);

  const handleSelectChat = async (connection) => {
    setSelectedChat(connection);
    try {
      const response = await axios.get(
        `/api/messages/${userId}/${connection.clientId}`
      );
      console.log("Fetched messages:", response.data);
      setMessages(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error fetching messages:", error);
      toast.error("Failed to load messages");
    }
  };

  const handleSendMessage = () => {
    if (message.trim() && selectedChat) {
      const recipientId =
        userId === selectedChat.adminId
          ? selectedChat.clientId
          : userId === selectedChat.clientId
          ? selectedChat.userId
          : selectedChat.adminId;

      const messageData = {
        senderId: userId,
        connectionId: selectedChat.connectionId,
        message,
      };

      socket.emit("sendMessage", messageData, (ack) => {
        if (ack.status === "ok") {
          setMessages((prevMessages) => [...prevMessages, ack.message]);
        } else {
          toast.error("Failed to send message");
        }
      });

      setMessage("");
    }
  };

  return (
    <div className={styles.chatInterface}>
      <div className={styles.sidebar}>
        {Array.isArray(connections) && connections.length > 0 ? (
          connections.map((conn) => {
            const connectionName =
              userId === conn.adminId
                ? conn.clientUsername || conn.userUsername
                : userId === conn.clientId
                ? conn.adminUsername || conn.userUsername
                : conn.adminUsername || conn.clientUsername;

            return (
              <div
                key={conn.connectionId}
                className={`${styles.connectionItem} ${
                  selectedChat?.connectionId === conn.connectionId
                    ? styles.active
                    : ""
                }`}
                onClick={() => handleSelectChat(conn)}
              >
                {connectionName}
              </div>
            );
          })
        ) : (
          <p>No connections available</p>
        )}
      </div>

      <div className={styles.chatBox}>
        {selectedChat ? (
          <>
            <div className={styles.messages} ref={messagesEndRef}>
              {Array.isArray(messages) &&
                messages.map((msg, index) => (
                  <div
                    key={index}
                    className={
                      msg.senderId === userId
                        ? styles.messageSent
                        : styles.messageReceived
                    }
                  >
                    <p>{msg.message}</p>
                  </div>
                ))}
            </div>

            <div className={styles.inputContainer}>
              <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type a message..."
                className={styles.messageInput}
              />
              <button onClick={handleSendMessage} className={styles.sendButton}>
                Send
              </button>
            </div>
          </>
        ) : (
          <p className={styles.selectChatMessage}>
            Select a connection to start chatting
          </p>
        )}
      </div>
    </div>
  );
};

export default ChatForm;
