import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { FaEdit, FaTrash, FaLink } from 'react-icons/fa';
import ConnectionModal from '../../ConnectionModal/ConnectionModal'; 
import EditUser from '../../editModal/EditUser';

const UserContainer = styled.div`
  padding: 20px;
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  color: white;
`;

const SearchBar = styled.input`
  padding: 8px;
  width: 30%;
  height: 30px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const UserTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th, td {
    padding: 2px;
    font-size: 10px;
    font-weight: semi-bold;
    border: 1px solid #ddd;
    text-align: left;
    color: white;
  }
`;

const ActionIcon = styled.span`
  cursor: pointer;
  margin-right: 10px;
  font-size: 14px;
  color: #007bff; 
  &:hover {
    color: #0056b3; 
  }
`;

const DeleteIcon = styled(ActionIcon)`
  color: #dc3545; 
  &:hover {
    color: #c82333; 
  }
`;

const User = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [connectionModalOpen, setConnectionModalOpen] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/api/register/users');
        setUsers(response.data);
        setFilteredUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
        toast.error('Failed to fetch users.');
      }
    };
    fetchUsers();
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setFilteredUsers(
      users.filter(
        (user) =>
          user.username.toLowerCase().includes(query.toLowerCase()) ||
          user.email.toLowerCase().includes(query.toLowerCase())
      )
    );
  };

  const handleDelete = async (userId) => {
    try {
      await axios.delete(`/api/register/users/${userId}`);
      toast.success('User deleted successfully.', {
        autoClose: 2000,
        onClose: () => {
          const updatedUsers = users.filter(user => user._id !== userId);
          setUsers(updatedUsers);
          setFilteredUsers(updatedUsers);
        }
      });
    } catch (error) {
      console.error('Error deleting user:', error);
      toast.error('Failed to delete user.');
    }
  };

  const openEditModal = (user) => {
    setSelectedUser(user);
    setModalOpen(true);
  };

  const closeEditModal = () => {
    setModalOpen(false);
    setSelectedUser(null);
  };

  const openConnectionModal = (user) => {
    setSelectedUser(user);
    setConnectionModalOpen(true);
  };

  const closeConnectionModal = () => {
    setConnectionModalOpen(false);
    setSelectedUser(null);
  };

  return (
    <UserContainer>
      <SearchContainer>
        <h4>Registered Users</h4>
        <SearchBar 
          type="text"
          placeholder="Search by username or email..."
          value={searchQuery}
          onChange={handleSearch}
        />
      </SearchContainer>
      <UserTable>
        <thead>
          <tr>
            <th>Sr.No</th>
            <th>Username</th>
            <th>Email</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((user, index) => (
            <tr key={user._id}>
              <td>{index + 1}</td> 
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>{user.role}</td>
              <td>
                <ActionIcon onClick={() => openEditModal(user)}>
                  <FaEdit />
                </ActionIcon>
                <DeleteIcon onClick={() => handleDelete(user._id)}>
                  <FaTrash />
                </DeleteIcon>
                <ActionIcon onClick={() => openConnectionModal(user)}>
                  <FaLink />
                </ActionIcon>
              </td>
            </tr>
          ))}
        </tbody>
      </UserTable>
      {modalOpen && (
        <EditUser
          user={selectedUser}
          isOpen={modalOpen}
          onClose={closeEditModal}
          onUpdate={(updatedUser) => {
            const updatedUsers = users.map(user => 
              user._id === updatedUser._id ? updatedUser : user
            );
            setUsers(updatedUsers);
            setFilteredUsers(updatedUsers);
            closeEditModal();
          }}
        />
      )}
      {connectionModalOpen && (
        <ConnectionModal
          user={selectedUser}
          isOpen={connectionModalOpen}
          onClose={closeConnectionModal}
        />
      )}
    </UserContainer>
  );
};

export default User;







