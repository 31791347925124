import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {Link, useNavigate } from "react-router-dom";
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import styled from "styled-components";
import { CircleLoader } from "react-spinners";
import signupImage from "../../../assets/signup.webp";

const Container = styled.div`
  background-color: #f0f4ff;
`;

const LeftSection = styled.div`
  color: white;
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0px;
`;

const StyledButton = styled.button`
  background-color: #007bff;
  border-radius: 10px;
  font-weight: bold;
`;

const OverlayText = styled.div`
  position: absolute;
  color: white;
  font-size: 24px;
  font-weight: bold;
  padding: 0 20px;
`;

const RegisterForm = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true); 
    try {
      await axios.post('/api/register', formData); 
      toast.success('Registration successfully', {
        position: 'top-center',
      });
      setFormData({ username: "", email: "", password: "" });
    } catch (error) {
      toast.error('Registration failed', {
        position: 'top-center',
      });
    } finally {
      setLoading(false); 
    }
  };
  return (
    <Container className="container-fluid vh-100 d-flex align-items-center justify-content-center"
    style={{ backgroundColor: "#F0F4FF" }}>
      <div className="row w-75 shadow-lg rounded-4 overflow-hidden">
        <LeftSection className="col-md-6">
          <OverlayText>One click to go all digital.</OverlayText>
          <img
            src={signupImage}
            alt="Signup Image"
            className="img-fluid"
          />
        </LeftSection>
        <div className="col-md-6 bg-white p-5">
          <h3 className="mb-4">Sign up</h3>
          <form onSubmit={handleRegister}>
            <div className="mb-3">
              <input
                type="text"
                name="username"
                className="form-control"
                placeholder="Enter username"
                id="username"
                value={formData.username}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <input
                type="email"
                name="email"
                id="email"
                className="form-control"
                placeholder="Email Address"
                value={formData.email}
                onChange={handleChange}
                autoComplete="email"
              />
            </div>
            <div className="mb-3">
              <input
                type="password"
                name="password"
                className="form-control"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                autoComplete="current-password"
              />
            </div>
            <div className="form-check mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="termsCheckbox"
              />
              <label className="form-check-label" htmlFor="termsCheckbox">
                You are agreeing to the Terms of Services and Privacy Policy
              </label>
            </div>

            {loading ? (
              <div className="text-center my-3">
                <CircleLoader size={100} color="#007bff" />
              </div>
            ) : (
              <StyledButton type="submit" className="btn btn-primary w-100">
                SignUp
              </StyledButton>
            )}
          </form>
          <p className="mt-3 text-center">
            Already a member? <Link to="/login">Sign in</Link>
          </p>
        </div>
      </div>
    </Container>
  );
};

export default RegisterForm;
